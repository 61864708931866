import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from "react-bootstrap";


const NoticiaDetail = () => {
    const { id } = useParams();

  const [noticia, setNoticia] = useState(null);

  useEffect(() => {
    const fetchNoticia = async () => {
      try {
        const response = await axios.get(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/noticias/${id}`);
        setNoticia(response.data);
      } catch (error) {
        console.error("Error fetching noticia:", error);
      }
    };

    fetchNoticia();
  }, [id]);

  if (!noticia) {
    return <div>Loading...</div>;
  }
console.log(noticia.titulo)
  return (
    <div className="">
    <div>
     <center><h1 classname='h1 p-5 botonbordeazul text-white px-5 mx-5 py-3'>{noticia.titulo}</h1></center>
    
      <center><img className="img-fluid max-height-300 p-5 foto" src={noticia.imagenUrl} alt={noticia.nombreimagen} /></center>
      <p></p>
      <div dangerouslySetInnerHTML={{ __html: noticia.contenido }} />

      
    </div>
    </div>
    
  );
};

export default NoticiaDetail;
