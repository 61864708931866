import React, { useEffect, useState } from 'react';
import { DataScroller } from 'primereact/datascroller';
import { Link } from 'react-router-dom';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './news.css';

const News = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    fetch('https://us-central1-inprojuy-f1173.cloudfunctions.net/app/noticias')
      .then(response => response.json())
      .then(data => {
        // Ordenar noticias por timestamp y tomar las 5 más recientes
        const sortedNews = data.sort((a, b) => b.timestamp - a.timestamp).slice(0, 5);
        setNews(sortedNews);
      });
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  return (
    <div className="news-container">
      <DataScroller value={news} itemTemplate={itemTemplate} rows={5} inline scrollHeight="500px" />
    </div>
  );

  function itemTemplate(newsItem) {
    return (
      <div className="news-item">
        <img src={newsItem.imagenUrl} alt={newsItem.titulo} className="news-image" />
        <div className="news-details">
          <Link to={`/noti/${newsItem.id}`} style={{ textDecoration: "none", color: "inherit" }}>
            <h2 className="news-title">{newsItem.titulo}</h2>
          </Link>
          <p className="news-date">{formatDate(newsItem.timestamp)}</p>
        </div>
      </div>
    );
  }
};

export default News;
