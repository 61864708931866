import React from 'react'

const CuandoSortea = () => {
  return (
    <div className='container-fluid fondotransparente4 vhcomp'> 
    <div className='contcaja'>
        <div className='cajaroja'>
            <h2 className='titulocaja'>¿Cuándo Sortea?</h2>
        </div>
        <div className='cuerpo'>
        <div className='botonbordeazul mx-auto my-3'>
        <img src='.\img\cuandosortea.jpg' className="centrar img-fluid my-3"/>
                    
                    </div>
                    <p><a href=".\download\cupones_sorteo.pdf" download >Asignación de numeros para el Próximo Sorteo</a></p>
                    
        </div>
    </div>
    </div>
  )
}

export default CuandoSortea