import React, { useState } from 'react';
import { subirArchivo, db } from "../../firebase/firebase.config";
import { collection, addDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

function Concurso() {
  const [files, setFiles] = useState([null, null, null]);
  const [formValues, setFormValues] = useState({
    nombre: '',
    apellido: '',
    dni: '',
    direccion: '',
    barrio: '',
    localidad: '',
    telefono: '',
    fechaNacimiento: '',
    nombrePadre: '',
    apellidoPadre: '',
    dniPadre: '',
    aceptoCondiciones: false,
    autorizoParticipacion: false,
    acuerdoExclusion: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que todos los campos estén completos
    const areAllFieldsFilled = Object.values(formValues).every(value => {
      if (typeof value === 'boolean') {
        return value === true;
      }
      return value.trim() !== '';
    });

    if (!areAllFieldsFilled || files.some(file => file === null)) {
      alert('Todos los campos deben ser completados');
      return;
    }

    // Validar que los archivos sean de extensión JPG y tengan un tamaño menor a 250KB
    const validSize = 250 * 1024; // 250KB

    const areFilesValid = files.every(file => {
      if (file.type !== 'image/jpeg') {
        alert('Los archivos deben ser de formato JPG');
        return false;
      }
      if (file.size > validSize) {
        alert('Los archivos deben tener un tamaño menor a 250KB');
        return false;
      }
      return true;
    });

    if (!areFilesValid) {
      return;
    }

    try {
      const urls = await Promise.all(files.map(file => subirArchivo(file)));

      const formData = {
        id: uuidv4(),
        ...formValues,
        fotos: urls
      };

      await addDoc(collection(db, 'concurso'), formData);

      console.log('Datos guardados en la base de datos:', formData);

      alert('Los datos se han cargado correctamente');
      window.location.href = 'https://www.inprojuy.gob.ar'; // Redireccionar a inprojuy.gob.ar
    } catch (error) {
      console.error(error);
      alert('Error al cargar los datos. Inténtalo de nuevo.');
    }
  };

  const handleFileChange = (e, index) => {
    const newFiles = [...files];
    newFiles[index] = e.target.files[0];
    setFiles(newFiles);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: fieldValue
    }));
  };

  return (
    <div className="container">
        <br/>
                <center><h2 className='botonbordeazul text-white px-5 py-3 mb-4'>Concurso de Dibujo</h2></center>
        <br/>
        <br/>
        <div className='fondotransparente m-auto'>
          <img src='.\img\ganadoresllama.jpg' className="centrar img-fluid adapt"/>
          <br/>
          <img src='.\img\concursollama2.jpg' className="centrar img-fluid adapt"/>
          </div>
          <br/>
          <br/>
          <br/>
          <ul>
          <center><li className='h5 sinvineta'><a className='h1 text-primary ' href=".\download\ConcursoLlamaInProJuy.pdf" download >Descargar Bases y condiciones</a></li></center>
      </ul>
      <br/>
  {/*     
      <center><h2 className='botonbordeazul text-white px-5 py-3 mb-4'>Formulario de participación</h2></center>
      <br/>

      <form onSubmit={handleSubmit} className="container fondotransparente form ">
      <h3>Datos del participante</h3>
      <br/>       

        <div className="form-group">
          <label className="h5" htmlFor="nombre">Nombre:</label>
          <input className="form-control col-6" type="text" id="nombre" name="nombre" value={formValues.nombre} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="h5" htmlFor="apellido">Apellido:</label>
          <input className="form-control col-6" type="text" id="apellido" name="apellido" value={formValues.apellido} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="h5" htmlFor="dni">DNI:</label>
          <input className="form-control col-6" type="text" id="dni" name="dni" value={formValues.dni} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="h5" htmlFor="direccion">Dirección:</label>
          <input className="form-control col-6" type="text" id="direccion" name="direccion" value={formValues.direccion} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="h5" htmlFor="barrio">Barrio:</label>
          <input className="form-control col-6" type="text" id="barrio" name="barrio" value={formValues.barrio} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="h5" htmlFor="localidad">Localidad:</label>
          <input className="form-control col-6" type="text" id="localidad" name="localidad" value={formValues.localidad} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="h5" htmlFor="telefono">Teléfono:</label>
          <input className="form-control col-6" type="text" id="telefono" name="telefono" value={formValues.telefono} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="h5" htmlFor="fechaNacimiento">Fecha de Nacimiento:</label>
          <input className="form-control col-6" type="date" id="fechaNacimiento" name="fechaNacimiento" value={formValues.fechaNacimiento} onChange={handleChange} />
        </div>
        <br/>
        <h3>Datos del Padre, Madre o Tutor</h3>
<br/>

        <div className="form-group">
          <label className="h5" htmlFor="nombrePadre">Nombre Padre:</label>
          <input className="form-control col-6" type="text" id="nombrePadre" name="nombrePadre" value={formValues.nombrePadre} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="h5" htmlFor="apellidoPadre">Apellido Padre:</label>
          <input className="form-control col-6" type="text" id="apellidoPadre" name="apellidoPadre" value={formValues.apellidoPadre} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label className="h5" htmlFor="dniPadre">DNI Padre:</label>
          <input className="form-control col-6" type="text" id="dniPadre" name="dniPadre" value={formValues.dniPadre} onChange={handleChange} />
        </div>


        <div className="input-group col-md-12 m-0">
        <label className="h5 col-md-12 form-label">Dibujo Participante </label>
          <input className="form-control  my-3 border px-3 py-2 rounded placeholder-gray-500" type='file' name='fotodni' id='fotodni1' onChange={(e) => handleFileChange(e, 0)} />
        </div>
        <div>
        <label className="h5 col-md-12 form-label">Foto DNI del participante  </label>
          <input className="form-control  my-3 border px-3 py-2 rounded placeholder-gray-500" type='file' name='fotodni' id='fotodni2' onChange={(e) => handleFileChange(e, 1)} />
        </div>
        <div>
        <label className="h5 col-md-12 form-label">Foto DNI del Padre, Madre o Tutor  </label>
          <input className="form-control  my-3 border px-3 py-2 rounded placeholder-gray-500" type='file' name='fotodni' id='fotodni3' onChange={(e) => handleFileChange(e, 2)} />
        </div>
        
        <div className="mb-3 form-check">
          <input className="form-check-input" type="checkbox" id="aceptoCondiciones" name="aceptoCondiciones" checked={formValues.aceptoCondiciones} onChange={handleChange} />
          <label className="h5" htmlFor="aceptoCondiciones">Acepto Bases y Condiciones</label>
        </div>
        <div className="mb-3 form-check">
          <input className="form-check-input" type="checkbox" id="autorizoParticipacion" name="autorizoParticipacion" checked={formValues.autorizoParticipacion} onChange={handleChange} />
          <label className="h5" htmlFor="autorizoParticipacion">Manifiesto con el carácter de Declaración Jurada, que autorizo al menor a participar del concurso</label>
        </div>
        <div className="mb-3 form-check">
          <input className="form-check-input" type="checkbox" id="acuerdoExclusion" name="acuerdoExclusion" checked={formValues.acuerdoExclusion} onChange={handleChange} />
          <label className="h5" htmlFor="acuerdoExclusion">Manifiesto estar de acuerdo con la exclusión y nulidad de la participación en el concurso, en caso de existir algun error, falsedad o falta de claridad en los datos y requerimientos anteriormente solicitados.</label>
        </div>

        <button className="btn btn-primary mt-4" type="submit">Subir Formulario</button>
      </form> */}
    </div>
  );
}

export default Concurso;
