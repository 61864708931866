import React from 'react';

const Ganadoresum = () => {
  return (
    <div className='container fondotransparente'>
      <div className='btn-titulo my-4'>
        <div className='botonbordeazul'>
          <h2 className='text-white px-5 mx-5 py-3 h1'>Ganadores</h2>
        </div>
      </div>
      <center>
        <div className=''>
          {/* Media query para dispositivos móviles */}
          <img
            className='img-fluid mobile-image'
            src='./img/ganadores-movil.jpg'
            alt='Imagen para dispositivos móviles'
          />

          {/* Media query para escritorio */}
          <img
            className='img-fluid desktop-image'
            src='./img/ganadores-escritorio.jpg'
            alt='Imagen para escritorio'
          />
        </div>
      </center>
    </div>
  );
};

export default Ganadoresum;
