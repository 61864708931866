import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Cupones() {

  const { id, ns } = useParams(); // Corrección aquí
  const navigate = useNavigate();

  const [cupones, setCupones] = useState([]);

  useEffect(() => {
    const getCupones = async () => {
      try {
        const response = await fetch(
          `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/cupones/${id}/${ns}`
        );
        const data = await response.json();
        setCupones(data);
        console.log('hola ' + id)
      } catch (error) {
        console.log("Error fetching cupones:", error);
      }
    };

    getCupones();
  }, [id]);

  return (
    <Container>
    <div className="table-responsive">
      <h2 className="h3 mt-4 mb-3">Cupones no Ganadores Cargados </h2>
      <button className='red' onClick={() => navigate('/login')}>Volver</button>

      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th>Cupón</th>
            <th>Fecha de carga</th>
            <th>Mes de sorteo</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(cupones) && cupones.length > 0 ? (
            cupones.map((cupon, index) => (
              <tr key={index}>
                <td>{cupon.cupon}</td>
                <td>{cupon.fechaCarga}</td>
                <td>{cupon.mesDeSorteo}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>Sin cupones cargados</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    </Container>
  );
}

export default Cupones;
