import * as XLSX from 'xlsx';


const ExcelUploader = () => {
  const handleFileChange = async (e) => {
    try {
      const file = e.target.files[0];

      if (!file) {
        console.error('No se seleccionó ningún archivo.');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });

          // Supongamos que la hoja en el archivo Excel se llama "Empleados"
          const sheetName = 'Empleados';
          const sheet = workbook.Sheets[sheetName];

          // Asumiendo que las columnas son nombre, apellido, cargo, dni
          const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Ajusta la lógica para interactuar con tu base de datos y enviar los datos al servidor
          for (let i = 1; i < rows.length; i++) {
            const [nombre, apellido, cargo, dni] = rows[i];

            // Asegúrate de ajustar el endpoint y el método según tus necesidades
            const bodyData = {
              nombre,
              apellido,
              cargo,
              dni,
            };

            console.log('Body del request:', bodyData);

            const response = await fetch('https://us-central1-inprojuy-f1173.cloudfunctions.net/app/agregarempleado', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(bodyData),
            });

            const responseData = await response.json();
            console.log('Mensaje del servidor:', responseData.message);
          }

          console.log('Datos cargados exitosamente');
        } catch (error) {
          console.error('Error al procesar el archivo Excel', error);
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('Error al manejar el cambio de archivo', error);
    }
  };

  return (
    <div>
      <h2>Cargar archivo con empleados</h2>
      <input type="file" onChange={handleFileChange} accept=".xlsx, .xls" />
      
    </div>
  );
};

export default ExcelUploader;
