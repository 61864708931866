import React from 'react'

const PorqueDeLaPrevencion = () => {
    return (
        <div className='container fondotransparente p-5 my-5 '>
            <center><h1 className=" p-5 botonbordeazul text-white px-5 mx-5 py-3">El Porqué de la Prevención</h1></center>
            <br />
            <br />
            <p>
                El <strong>lavado de activos</strong> y la <strong>financiación del terrorismo</strong> son problemas serios y
                complejos que afectan a países de todo el mundo. Estos delitos no solo dañan las economías y las estructuras
                gubernamentales, sino que también representan una amenaza significativa para la <strong>seguridad nacional e
                    internacional</strong>. Aquí explicamos por qué es tan crucial tomar medidas para prevenirlos.
            </p>
            <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                            Impacto en la Sociedad y la Economía
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                        <div className="accordion-body">
                            <ul>
                                <li><strong>Efectos negativos</strong>: El lavado de dinero permite que los delincuentes disfruten de las ganancias
                                    de actividades ilícitas, afectando la estabilidad económica y el desarrollo del país.</li>
                                <li><strong>Amenaza a la seguridad</strong>: La financiación del terrorismo proporciona los recursos necesarios
                                    para llevar a cabo actos violentos que ponen en riesgo vidas y generan miedo en la sociedad.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                            Desafíos en la Prevención
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                        <div className="accordion-body">
                            <p>
                                Estos delitos son particularmente difíciles de combatir porque:
                            </p>
                            <ul>
                                <li>Las <strong>técnicas de los delincuentes</strong> evolucionan constantemente, lo que hace que las estrategias
                                    de prevención también tengan que adaptarse y mejorar.
                                </li>
                                <li>Es necesario un alto grado de <strong>cooperación internacional</strong> para detener el flujo de dinero
                                    ilícito a través de fronteras.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                            Importancia de la Coordinación
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                        <div className="accordion-body">
                            <p>
                                La lucha contra el lavado de dinero y la financiación del terrorismo requiere:
                            </p>
                            <ul>
                                <li><strong>Medidas uniformes</strong>: Es esencial que los países trabajen juntos y sigan las mismas pautas para
                                    prevenir estos delitos.</li>
                                <li><strong>Esfuerzos coordinados</strong>: Gobiernos, instituciones financieras y otras organizaciones deben
                                    colaborar para asegurar que las normativas se apliquen de manera efectiva.</li>
                            </ul>
                            <p>
                                En resumen, el compromiso con la prevención no solo protege la economía y el bienestar social, sino que también
                                contribuye a mantener un mundo más seguro para todos.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PorqueDeLaPrevencion