import React from 'react'

const QueEsGafi = () => {
    return (
        <div className='container fondotransparente p-5 my-5 '>
            <center><h1 className=" p-5 botonbordeazul text-white px-5 mx-5 py-3"> ¿Qué es el Grupo de Acción Financiera (GAFI)?</h1></center>
            <br />
            <br />
            <p>
                El <strong>Grupo de Acción Financiera (GAFI)</strong> es una organización intergubernamental creada en 1989 por
                los países integrantes del <strong>G-7</strong>. Su principal objetivo es desarrollar y promover políticas que
                ayuden a prevenir y combatir el <strong>lavado de activos</strong>, la <strong>financiación del terrorismo </strong>
                y la <strong>financiación de la proliferación de armas de destrucción masiva (LA/FT/FPADM)</strong>.
            </p>
            <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                            Rol y Funciones del GAFI
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                        <div className="accordion-body">
                            <ul>
                                <li><strong>Fijar estándares internacionales</strong>: El GAFI establece normas que todos los países deben seguir
                                    para proteger el sistema financiero internacional de estas amenazas.</li>
                                <li><strong>Promover la implementación efectiva</strong>: A través de sus políticas, el GAFI incentiva a los
                                    gobiernos a tomar medidas legales, regulatorias y operativas que sean eficaces para combatir estos delitos.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                            Recomendaciones del GAFI
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                        <div className="accordion-body">
                            <p>
                                El GAFI ha creado una serie de <strong>Recomendaciones</strong> conocidas como los <strong>Estándares
                                    Internacionales sobre la Lucha contra el Lavado de Activos y la Financiación del Terrorismo</strong>. Estas
                                recomendaciones proporcionan un marco completo que los países deben seguir para responder de manera coordinada a
                                estas amenazas.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                            Evaluación de los Miembros
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                        <div className="accordion-body">
                            <p>
                                El GAFI también evalúa regularmente a sus miembros para asegurarse de que estén implementando de manera efectiva
                                las medidas necesarias. Este seguimiento es clave para garantizar que los esfuerzos de prevención y control sean
                                constantes y efectivos.
                            </p>
                            <p>
                                En resumen, el GAFI juega un papel crucial en la protección de la integridad del sistema financiero internacional,
                                así como en la seguridad y la paz mundial, al promover políticas y medidas contra el lavado de dinero y la
                                financiación del terrorismo.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QueEsGafi