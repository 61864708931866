import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from "../../context/AuthContext";
import { useParams } from 'react-router-dom';

const ConcursoRespEditForm = () => {
    const { id } = useParams();
    const auth = useAuth();
    const [formData, setFormData] = useState({
        usuario: auth.user,
        link: '',
        escuela: '',
        alumno_o_curso: '',
        alumnos_participantes: [{ dni_menor: '', nombre_menor: '', fecha_nac_menor: '' }]
    });

    console.log('Hola ' + id);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/concursoresp/${id}`);
                setFormData(response.data);
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        };
        fetchData();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAlumnosChange = (index, e) => {
        const { name, value } = e.target;
        const updatedAlumnos = formData.alumnos_participantes.map((alumno, i) =>
            i === index ? { ...alumno, [name]: value } : alumno
        );
        setFormData({ ...formData, alumnos_participantes: updatedAlumnos });
    };

    const addAlumno = () => {
        setFormData({
            ...formData,
            alumnos_participantes: [...formData.alumnos_participantes, { dni_menor: '', nombre_menor: '', fecha_nac_menor: '' }]
        });
    };

    const removeAlumno = (index) => {
        const updatedAlumnos = formData.alumnos_participantes.filter((alumno, i) => i !== index);
        setFormData({ ...formData, alumnos_participantes: updatedAlumnos });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/concursorespmod/${id}`, formData);
            console.log('Documento modificado:', response.data);
            alert('El documento se modificó correctamente.');
        } catch (error) {
            console.error('Error al modificar el documento:', error);
            alert('Hubo un error al modificar el documento. Por favor, inténtalo de nuevo.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <center><h2 className='botonbordeazul text-white px-5 py-3 mb-4'>Modificar Video del Concurso</h2></center>

            <div>
                <label className="h5 form-label">Link:</label>
                <input className="form-control col-6" type="text" name="link" value={formData.link} onChange={handleInputChange} required />
            </div>
            <div>
                <label className='h5 form-label'>Escuela:</label>
                <input className="form-control col-6" type="text" name="escuela" value={formData.escuela} onChange={handleInputChange} required />
            </div>
            <div>
                <label className='h5 form-label'>Alumno o Curso:</label>
                <input className="form-control col-6" type="text" name="alumno_o_curso" value={formData.alumno_o_curso} onChange={handleInputChange} required />
            </div>

            {/* Separación y tabulación */}
            <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                <label className='h4 form-label'>Alumnos Participantes:</label>
                {formData.alumnos_participantes.map((alumno, index) => (
                    <div key={index} style={{ marginTop: '20px' }}>
                        <label className="h5 form-label">Alumno {index + 1}:</label> <p></p>
                        <button className="eliminar-alumno" type="button" onClick={() => removeAlumno(index)}>Eliminar Alumno {index + 1}</button>
                        <p></p>
                        <label className="h5 form-label">DNI:</label>
                        <input
                            className="form-control col-6"
                            type="text"
                            name="dni_menor"
                            placeholder="DNI del menor"
                            value={alumno.dni_menor}
                            onChange={(e) => handleAlumnosChange(index, e)}
                            required
                        />
                        <label htmlFor="nombre" className="h5">Nombre:</label>
                        <input
                            className="form-control col-6"
                            type="text"
                            name="nombre_menor"
                            placeholder="Nombre del menor"
                            value={alumno.nombre_menor}
                            onChange={(e) => handleAlumnosChange(index, e)}
                            required
                        />
                        <label htmlFor="nombre" className="h5">Fecha de Nacimiento:</label>
                        <input
                            className="form-control col-6"
                            type="date"
                            name="fecha_nac_menor"
                            placeholder="Fecha de Nacimiento del menor"
                            value={alumno.fecha_nac_menor}
                            onChange={(e) => handleAlumnosChange(index, e)}
                            required
                        />
                    </div>
                ))}
                <button className="agregar-alumno" style={{ marginTop: '20px' }} type="button" onClick={addAlumno}>Agregar Alumno</button>
            </div>
            {/* Fin de separación y tabulación */}

            <div style={{ marginTop: '30px' }}>
                <button type="submit">Modificar Documento</button>
            </div>
        </form>
    );
};

export default ConcursoRespEditForm;
