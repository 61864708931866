import React from 'react'
import { Link } from 'react-router-dom'

const ComoTrabajamos = () => {
    return (
        <div className='container fondotransparente p-5'>
            <center>
                <h1 className="botonbordeazul text-white px-5 mx-5 py-3">¿Cómo trabajamos?</h1>
            </center>
           

            <center>
                <div className=''>
                    {/* Media query para dispositivos móviles */}
                    <img
                        className='img-fluid mobile-image'
                        src='.\img\INPROJUY_0800_movil_1.jpg'
                        alt='Imagen para dispositivos móviles'
                    />

                    {/* Media query para escritorio */}
                    <img
                        className='img-fluid desktop-image'
                        src='.\img\INPROJUY_0800.jpg'
                        alt='Imagen para escritorio'
                    />
                </div>
            </center>
            <div className="text-justify">
                <p>
                    En INPROJUY promovemos el juego responsable mediante un enfoque integral 
                    que combina entrevistas, asesoramiento, orientación y contención     
                    familiar. A través de un cuestionario inicial, evaluamos el contexto 
                    social, psicológico, laboral y económico de cada persona para comprender 
                    su situación y ofrecerle un asesoramiento adecuado sobre el proceso de 
                    autoexclusión y sobre la problemática del juego. Nuestro equipo orienta 
                    a las personas autoexcluidas hacia instituciones de salud pública para 
                    un tratamiento psicológico si es necesario, asegurando un proceso de 
                    ayuda confidencial y gratuito. Además, invitamos a familiares a 
                    participar, brindándoles herramientas para apoyar a sus seres queridos y 
                    ayudándolos a entender la magnitud de los riesgos asociados con el 
                    consumo problemático. En INPROJUY estamos comprometidos con fomentar 
                    hábitos de juego seguros y saludables, recordando que el juego debe ser 
                    solo un entretenimiento y no una vía para resolver problemas financieros 
                    o emocionales.
                </p>
            </div>
            <ul>
                <li>Entrevista: Se realiza un cuestionario con la finalidad de determinar la situación actual y personal de quien acude, considerando sus diferentes aspectos: sociales, psicológico, laboral y económico. &nbsp;Podes acercarte al INPROJUY, o llamar por teléfono</li>
                <li>Asesoramiento e información: Se brinda información respecto al formulario de autoexclusión y en relación a la problemática del juego.</li>
                <li>Orientación y derivación: a&nbsp; toda persona autoexcluida, se la orienta en cuanto a la posibilidad de realizar un tratamiento psicológico en alguna&nbsp; institución de salud pública cercana a su domicilio.</li>
                <li>Contención familiar: A la persona interesada en autoexcluirse se le propone, que asista acompañado por un familiar, con el objetivo de que éste brinde su apoyo y para ofrecerle una charla orientativa e informativa acerca de la problemática.</li>
                <li>Confidencial y Gratuito: Este programa es absolutamente confidencial y gratuito.</li>
            </ul>

            <p><strong>Cuando son un problema  las apuestas?</strong></p>

            <ol>
                <li>CUANDO INTERFIEREN CON EL TRABAJO, ESTUDIOS U OTRAS ACTIVIDADES</li>
                <li>CUANDO ORIGINAN PROBLEMAS EMOCIONALES O DE SALUD</li>
                <li>CUANDO GENERAN PROBLEMAS FINANCIEROS</li>
                <li>CUANDO PONEN EN PELIGRO LA FAMILIA U OTRAS RELACIONES PERSONALES</li>
            </ol>

            <p><strong>Como abordar la situación?</strong></p>

            <ol>
                <li>No juzgue o critique a quién posiblemente tiene problema con el juego por no poder manejar su conducta.</li>
                <li>No intente decidir por él o ella, sino manifiéstele sus intenciones de ayudarlo.</li>
                <li>Hable con él o ella, posibilitando un clima distendido y acogedor, en el cual le exprese como afecta no solo a su persona, sino también a su entorno mas cercano.</li>
                <li>Facilitele información en lugar de consejos. &nbsp;Pásale la dirección de esta web del Inprojuy y el teléfono de ayuda. También los lugares mas cercanos de atención de la Secretaria de Salud mental y adicciones&nbsp; (DTC) <Link to={'/lugaresdeayuda'}>ver lugares de ayuda</Link></li>
            </ol>

            <p><strong>Tips para disfrutar y jugar en forma responsable</strong></p>

            <p>Recordamos que la práctica de juegos sin control puede crear adicción, por eso queremos dar algunos consejos para disfrutar y jugar de forma responsable:</p>

            <ul>
                <li>Nunca excedas tus límites según tu situación económica.</li>
                <li>El juego de azar es un entretenimiento, nunca una forma de resolver tu situación financiera.</li>
                <li>No juegues bajo la influencia de alcohol o drogas.</li>
                <li>No pidas dinero prestado para jugar.</li>
                <li>Si pierdes, no sigas jugando para recuperar tu dinero.</li>
                <li>No juegues si tu estado esta alterado.</li>
            </ul>
        </div>
    )
}

export default ComoTrabajamos
