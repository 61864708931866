import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';


function AutoexcluidosPanel() {
  const [sorteos, setSorteos] = useState([]);
  const [selectedSorteo1, setSelectedSorteo1] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [userDataByEmail, setUserDataByEmail] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await axios.get(
          `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`
        );
        setUserRole(response.data.role);
      } catch (error) {
        console.error("Error al obtener el rol del usuario:", error);
      }
    };

    fetchUserRole();
  }, [auth.id]);

  const renderButtons = () => {
    if (userRole === "admin" || userRole === "adminauto") {
      return (
        <>
          <button onClick={() => navigate('/AutoexcluidosGallery')}>
            <div className=''>
              Ver Galeria de Autoexcluidos
            </div>
          </button>
          <p />
          <button onClick={() => navigate('/AutoexcluidosLookup')}>
            <div className=''>
              Buscar Autoexcluidos
            </div>
          </button>
          <p />
          <button onClick={() => navigate('/InformeAsistencia')}>
            <div className=''>
              Informe Asistencia Autoexcluidos
            </div>
          </button>
          <p />
          <button onClick={() => navigate('/formauto')}>
            <div className=''>
              Alta de Autoexcluidos
            </div>
          </button>
          <p />
          <button onClick={() => navigate('/BDempleados')}>
            <div className=''>
              Ver Empleados
            </div>
          </button>
          <p></p>
          <button onClick={() => navigate('/BDAutoexcluidos')}>
            <div className=''>
              BD Auto Excluidos
            </div>
          </button>
        </>
      );
    } else if (userRole === "agente") {
      return (
        <>
          <button onClick={() => navigate('/AutoexcluidosGallery')}>
            <div className=''>
              Ver Galeria de Autoexcluidos
            </div>
          </button>
          <p />
          <button onClick={() => navigate('/AutoexcluidosLookup')}>
            <div className=''>
              Buscar Autoexcluidos
            </div>
          </button>
          <p />
          <button onClick={() => navigate('/BDempleados')}>
            <div className=''>
              Ver Empleados
            </div>
          </button>
          <p></p>
          <button onClick={() => navigate('/BDAutoexcluidos')}>
            <div className=''>
              BD Auto Excluidos
            </div>
          </button>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div id="panelAuto" style={{ border: '1px solid #000', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', padding: '10px', borderRadius: '5px' }}>
      <center><h1 className="botonbordeazul text-white px-5 mx-5 py-3 h1">Panel de Autoexcluidos</h1></center>
      <p />
      {renderButtons()}
    </div>
  );
}

export default AutoexcluidosPanel;
