import React, { useEffect, useState } from 'react';
import Cards from '../../components/Cards/Cards';
import News from './news/news';

const Section5 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {isMobile ? <News /> : <Cards />}
    </div>
  );
};

export default Section5;
