// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage"
import {v4} from 'uuid';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAKr3HmMS8IEykgWGlYwm42HkVr5kMYSas",
  authDomain: "inprojuy-f1173.firebaseapp.com",
  projectId: "inprojuy-f1173",
  storageBucket: "inprojuy-f1173.appspot.com",
  messagingSenderId: "752172358676",
  appId: "1:752172358676:web:94e465703ad362877b1b01"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth(app)
export const storage = getStorage(app)

export async function subirArchivo(file){
  const storageRef = ref(storage,`llama/${v4()}`)
  await uploadBytes(storageRef,file)
  const url = await getDownloadURL(storageRef)
  return url

}

export async function subirFotoAuto(file){
  const storageRef = ref(storage,`autoexcluidos/${v4()}`)
  await uploadBytes(storageRef,file)
  const url = await getDownloadURL(storageRef)
  return url

}


export async function subirPdfAuto(file){
  const storageRef = ref(storage,`autoexcluidosPdf/${v4()}.pdf`)
  await uploadBytes(storageRef,file)
  const url = await getDownloadURL(storageRef)
  return url

}

export async function subirFotDenuncia(file){
  const storageRef = ref(storage,`denuncias/${v4()}`)
  await uploadBytes(storageRef,file)
  const url = await getDownloadURL(storageRef)
  return url

}

//Inicio concurso videos
export async function subirPdfcesion(file){
  const storageRef = ref(storage,`cesionPdf/${v4()}.pdf`)
  await uploadBytes(storageRef,file)
  const url = await getDownloadURL(storageRef)
  return url

}

export async function subirPdfInscripcion(file){
  const storageRef = ref(storage,`inscripcionPdf/${v4()}.pdf`)
  await uploadBytes(storageRef,file)
  const url = await getDownloadURL(storageRef)
  return url

}

// fin concurso videos

export async function subirSlider(file){
  const storageRef = ref(storage,`sliders/${v4()}`)
  await uploadBytes(storageRef,file)
  const url = await getDownloadURL(storageRef)
  return url

}

export async function subirINoticia(file) {
  const imageName = v4(); // Generar un nombre único para la imagen
  const storageRef = ref(storage, `Noticias/${imageName}`);
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return { url, imageName };

}