import React, { useState } from 'react';
import { subirINoticia, db } from "../../firebase/firebase.config";
import { collection, addDoc } from "firebase/firestore";
import EditorDeTexto from './EditorDeTezto'; 

function CargarNoticia() {
  const [titulo, setTitulo] = useState('');
  const [imagen, setImagen] = useState(null);
  const [contenido, setContenido] = useState('');

  const handleTituloChange = (e) => {
    setTitulo(e.target.value);
  };

  const handleImagenChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setImagen(selectedImage);
    }
  };

  const handleContenidoChange = (content) => {
    setContenido(content);
  };

  const enviarNoticia = async () => {
    if (!titulo || !imagen || !contenido) {
      alert('Por favor, complete todos los campos.');
      return;
    }

    try {
        const { url, imageName } = await subirINoticia(imagen); // Obtener la URL y el nombre de la imagen
        const timestamp = Date.now();
      const noticiaData = {
        id: timestamp,
        titulo: titulo,
        imagenUrl: url,
        contenido: contenido,
        timestamp: timestamp,
        nombreimagen:imageName,
      };

      await addDoc(collection(db, 'noticias'), noticiaData);

      alert('Noticia cargada correctamente');
      setTitulo('');
      setImagen(null);
      setContenido('');
      window.location.reload();
    } catch (error) {
      console.error(error);
      alert('Error al cargar la noticia. Inténtalo de nuevo.'+error);
      window.location.reload();
    }
  };

  return (
    <div>
      <h2>Cargar Noticia</h2>
      <input
        type="text"
        placeholder="Título de la noticia"
        value={titulo}
        onChange={handleTituloChange}
      />
      <input
        type="file"
        accept="image/jpeg, image/png"
        onChange={handleImagenChange}
      />
      <EditorDeTexto onChange={handleContenidoChange} value={contenido} />
      <button onClick={enviarNoticia}>Cargar Noticia</button>
    </div>
  );
}

export default CargarNoticia;
