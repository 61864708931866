import React, { useState } from 'react';
import axios from 'axios';
import { Container, Modal } from 'react-bootstrap';
import AutoexcluidosDetalle from './AutoexcluidosDetalle';
import { DataGrid } from '@mui/x-data-grid';

function AutoexcluidosLookup() {
  const [searchType, setSearchType] = useState('dni');
  const [searchValue, setSearchValue] = useState('');
  const [autoexcluidos, setAutoexcluidos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedAutoexcluido, setSelectedAutoexcluido] = useState(null);
  const [selectedEmpleado, setSelectedEmpleado] = useState(null);
  const [loading, setLoading] = useState(false);
  const columns = [
    { field: 'nombre', headerName: 'Nombre', width: 130 },
    { field: 'fechaNacimiento', headerName: 'Fecha de Nacimiento', width: 180 },
    { field: 'vencimiento', headerName: 'Vencimiento', width: 180 },
    { field: 'dni', headerName: 'DNI', width: 130 },
    { field: 'telefono', headerName: 'Teléfono', width: 130 },
    {
      field: 'action',
      headerName: 'Detalle',
      width: 170,
      renderCell: (params) => (
        params.row.empleado 
          ? <span>EMPLEADO INPROJUY</span>
          : <button onClick={() => handleRowClick(params.row)}>Ver Detalle</button>
      ),
    },
  ];

  const handleRowClick = (row) => {
    setShowModal(true);
    setSelectedAutoexcluido(row);
  };

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
    setSearchValue('');
  };

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    if ((searchType === 'dni' && /^\d*$/.test(inputValue)) || (searchType === 'nombre' && /^[a-zA-Z\s]*$/.test(inputValue))) {
      setSearchValue(inputValue);
    }
  };

  const handleSearch = async (event) => {
  event.preventDefault();
  try {
    setLoading(true);
    let response;
    if (searchType === 'dni') {
    response = await axios.get(
      `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/autoexcluidos/${searchValue}`
    );
  
    if (response.data.empleado) {
      setShowModal(true);
      setSelectedEmpleado({
        nombre: response.data.nombre,
        dni: response.data.dni,
        cargo: response.data.cargo // o el nombre del campo que corresponda al cargo
      });
      setSelectedAutoexcluido(null); // Reiniciar el estado del autoexcluido
    } else {
      setShowModal(true);
      setSelectedAutoexcluido(response.data);
      setSelectedEmpleado(null); // Reiniciar el estado del empleado
    }
    }
    else {
      response = await axios.get(
        `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/autoexcluidos/buscar/${searchValue}`
      );
      setAutoexcluidos(response.data);
    
    }
  } catch (error) {
    console.error('Error fetching autoexcluidos', error);
  } finally {
    setLoading(false);
  }
};
  return (
    <div className="container-fluid py-5 fondotransparente5">
      <Container style={{ height: '100%', width: '100%' }}>
        <div style={{ height: '100%', width: '100%' }}>
          <center>
            <h2 className="botonbordeazul text-white px-5 py-3 mb-4">Buscar Autoexcluido</h2>
          </center>
          <br />
          <form onSubmit={handleSearch}>
            <div classname="radio-buttons-container">
            <div classname="radio-button">
                <input name="radio-group" id="radio2" classname="radio-button__input" type="radio" value="dni" checked={searchType === 'dni'} onChange={handleSearchTypeChange} />
                <label for="radio2" classname="radio-button__label">
                  <span classname="radio-button__custom"></span>

                  Buscar por DNI
                </label>
              </div>
              <div classname="radio-button">
                <input name="radio-group" id="radio1" classname="radio-button__input" type="radio" value="nombre" checked={searchType === 'nombre'} onChange={handleSearchTypeChange} />
                <label for="radio1" classname="radio-button__label">
                  <span classname="radio-button__custom"></span>

                  Buscar por nombre
                </label>
              </div>
              <input
                type={searchType === 'dni' ? 'number' : 'text'}
                value={searchValue}
                onChange={handleSearchInputChange}
                disabled={loading}
              />
              <button type="submit" disabled={loading}>
                {loading ? 'Buscando...' : 'Buscar'}
              </button>
            </div>
          </form>
          <br />
          {selectedEmpleado && (
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Empleado INPROJUY</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Nombre: {selectedEmpleado.nombre}</p>
                <p>DNI: {selectedEmpleado.dni}</p>
                <p>Cargo: {selectedEmpleado.cargo}</p>
                <button onClick={() => setShowModal(false)}>Cerrar</button>
              </Modal.Body>
            </Modal>
          )}
          {selectedAutoexcluido && (
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Detalle del Autoexcluido</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AutoexcluidosDetalle
                  autoexcluidoData={selectedAutoexcluido}
                  handleModalClose={() => setShowModal(false)}
                />
              </Modal.Body>
            </Modal>
          )}
                  {searchType === 'nombre' && (
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={autoexcluidos}
                columns={columns}
                pageSize={5}
                loading={loading}
                checkboxSelection={false}
              />
            </div>
          )}
        </div>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Detalle del Autoexcluido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAutoexcluido && (
            <AutoexcluidosDetalle
              autoexcluidoData={selectedAutoexcluido}
              handleModalClose={() => setShowModal(false)}
            />
          )}
        </Modal.Body>
      </Modal>

    </div>
  );
}

export default AutoexcluidosLookup;
