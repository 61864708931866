import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { daDK } from '@mui/x-data-grid/locales';

import nbNO from '@mui/x-data-grid/locales/nbNO';
import * as XLSX from 'xlsx';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const BDEmpleados = () => {
  const [empleados, setEmpleados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const url = 'https://us-central1-inprojuy-f1173.cloudfunctions.net/app/empleados';

    axios.get(url)
      .then((response) => {
        setEmpleados(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al obtener los datos de empleados:', error);
        setLoading(false);
      });
  }, []);

  const columns = [
    { field: 'nombre', headerName: 'Nombre', width: 130 },
    { field: 'apellido', headerName: 'Apellido', width: 130 },
   // { field: 'fechaNacimiento', headerName: 'Fecha de Nacimiento', width: 180 },
    { field: 'cargo', headerName: 'Cargo', width: 130 },
    { field: 'dni', headerName: 'CUIL', width: 130 },
    // Agrega más columnas según tus necesidades
  ];

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(empleados);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Empleados');
    XLSX.writeFile(wb, 'empleados.xlsx');
  };

  return (
    <Container style={{ height: 650, width: '100%' }}>
      <div style={{ height: 500, width: '100%' }}>
        <p></p>
        <center><h1 className='botonbordeazul p-2 text-white'>Base de Datos de Empleados</h1></center>
        <button onClick={exportToExcel}>Exportar a Excel</button> <a></a>
        <button className='red' onClick={() => navigate('/login')}>Volver</button>
        <p></p>

        {loading ? (
          <div id="load">
            <div>O</div>
            <div>D</div>
            <div>N</div>
            <div>A</div>
            <div>G</div>
            <div>R</div>
            <div>A</div>
            <div>C</div>
          </div>
        ) : (
          <DataGrid
            rows={empleados}
            columns={columns}
            pageSize={pageSize}
            pagination
            onPageChange={(params) => {
              setCurrentPage(params.page);
            }}
            onPageSizeChange={(params) => {
              setPageSize(params.pageSize);
            }}
          />
        )}
      </div>
    </Container>
  );
};

export default BDEmpleados;
