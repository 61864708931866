import React from 'react';
import Salas from '../../components/salas/Salas';
import JuegoOnline from '../../components/juegoonline/JuegoOnline';
import styled from 'styled-components';

const FullHeightDiv = styled.div`
  height: 85vh   /* Ocupa el 100% del alto del contenedor */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra el contenido verticalmente */
`;

const Section2 = () => {
  return (
    <FullHeightDiv>
      <Salas />
      {/* <JuegoOnline /> */}
    </FullHeightDiv>
  );
};

export default Section2;
