import React, { useState, useEffect } from 'react';
import Logout from '../Logout';
import Administracion from './Administracion';
import SearchRolesByEmail from './SearchRolesByEmail';
import ConcursoResppanel from './DashConcurso';
import AutoexcluidosPanel from './AutoexcluidosPanel';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

const Dashboard = () => {
    const auth = useAuth();
    const [selectedOption, setSelectedOption] = useState('perfilUsuario');
    const [userRole, setUserRole] = useState(null);

    const logoutButton = () => {
        try {
            auth.logout();
        } catch (error) {
            console.log();
        }
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                const response = await axios.get(
                    `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`
                );
                setUserRole(response.data.role);
            } catch (error) {
                console.error("Error al obtener el rol del usuario:", error);
            }
        };
        fetchUserRole();
    }, [auth.id]);

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '200px', backgroundColor: '#f0f0f0', padding: '20px', boxSizing: 'border-box' }}>
                <h2>Dashboard</h2>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                    {userRole !== 'user' && (
                        <>
                            {userRole === 'admin' && (
                                <>
                                    <li style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleOptionClick('otraOpcion')}>
                                        Panel de Administración
                                    </li>
                                    <li style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleOptionClick('auto')}>
                                        Panel de Autoexcluidos
                                    </li>
                                    <li style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleOptionClick('roles')}>
                                        Usuarios y Roles
                                    </li>
                                    <li style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleOptionClick('concurso')}>
                                    Jugá por Jugar
                                    </li>
                                </>
                            )}
                            {userRole === 'adminauto' && (
                                <>
                                    <li style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleOptionClick('concurso')}>
                                        Concurso Juego Responsable
                                    </li>
                                    <li style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleOptionClick('auto')}>
                                        Panel de Autoexcluidos
                                    </li>
                                </>
                            )}
                            {userRole === 'agente' && (
                                <li style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleOptionClick('auto')}>
                                    Panel de Autoexcluidos
                                </li>
                            )}
                            {userRole === 'escuela' && (
                                <li style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleOptionClick('concurso')}>
                                    Jugá por Jugar
                                </li>
                            )}
                             {userRole === 'jurado' && (
                                <li style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleOptionClick('concurso')}>
                                    Jugá por Jugar
                                </li>
                            )}
                        </>
                    )}
                    <li onClick={() => handleOptionClick('perfilUsuario')}>
                        Perfil de Usuario
                    </li>
                    <p></p>
                    <li style={{ marginBottom: '20px', cursor: 'pointer' }}>
                        <button className="btn-class-name" onClick={logoutButton}>
                            <span className="back"></span>
                            <span className="front">Salir</span>
                        </button>
                    </li>
                </ul>
            </div>

            <div style={{ flex: 1, padding: '20px' }}>
                {selectedOption === 'perfilUsuario' && <Logout />}
                {selectedOption === 'otraOpcion' && <Administracion />}
                {selectedOption === 'roles' && <SearchRolesByEmail />}
                {selectedOption === 'concurso' && <ConcursoResppanel />}
                {selectedOption === 'auto' && <AutoexcluidosPanel />}
            </div>
        </div>
    );
};

export default Dashboard;
