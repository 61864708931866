import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";


const AddSorteoForm = () => {
  const [sorteoData, setSorteoData] = useState({
    ano: "",
    fechasorteo: "",
    mes: "",
    n_sorte: "",
    ultimoing: "",
  });

  const [lastSorteos, setLastSorteos] = useState([]);
  const auth = useAuth();



  const fetchLastSorteos = async () => {
    try {
      const response = await axios.get("https://us-central1-inprojuy-f1173.cloudfunctions.net/app/sorteos");
      const sortedSorteos = response.data.sort((a, b) => b.n_sorte - a.n_sorte);
      const lastThreeSorteos = sortedSorteos.slice(0, 3);
      setLastSorteos(lastThreeSorteos);
    } catch (error) {
      console.error("Error al obtener los últimos sorteos", error);
    }
  };

  useEffect(() => {
    // Obtener los últimos 3 sorteos al cargar el componente
    fetchLastSorteos();
  }, []); // Solo se ejecuta al montar el componente

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSorteoData({ ...sorteoData, [name]: value });
  };

  const validateUserRole = async () => {
    try {
      const response = await axios.get(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`);
      const userRole = response.data.role;
      return userRole === "admin";
    } catch (error) {
      console.error("Error al obtener el rol del usuario", error);
      return false; // Manejar el error según tus necesidades
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

        // Validar el rol del usuario
        const isAdmin = await validateUserRole();

        if (!isAdmin) {
          alert("No tienes permisos para agregar sorteos. Debes ser un administrador.");
          return;
        }

    // Validar y asignar el número de sorteo
    const nextNSorte = lastSorteos.length > 0 ? lastSorteos[0].n_sorte + 1 : 1;
    setSorteoData({ ...sorteoData, n_sorte: nextNSorte });

    // Convertir el mes seleccionado a una marca de tiempo del primer día del mes
    const selectedMonth = new Date(sorteoData.ano, sorteoData.mes);
    const firstDayOfMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1, 0, 0, 0, 0);
    const fechasorteoTimestamp = firstDayOfMonth.toISOString();

    // Convertir las fechas de 'fechasorteo' y 'ultimoing' a marcas de tiempo
    const fechasorteoISO = new Date(sorteoData.fechasorteo).toISOString();
    const ultimoingISO = new Date(sorteoData.ultimoing).toISOString();

    try {
      // Enviar datos del nuevo sorteo al servidor
      await axios.post("https://us-central1-inprojuy-f1173.cloudfunctions.net/app/sorteos", {
        ano: sorteoData.ano,
        fechasorteo: fechasorteoISO,
        mes: fechasorteoTimestamp,
        n_sorte: nextNSorte,
        ultimoing: ultimoingISO,
      });

      // Actualizar la lista de últimos sorteos después de agregar uno nuevo
      fetchLastSorteos();

      alert("Sorteo agregado exitosamente");
    } catch (error) {
      console.error("Error al agregar el sorteo", error);
      alert("Hubo un error al agregar el sorteo. Por favor, intenta nuevamente.");
    }
  };

  return (
    <div>
      <h2>Últimos 3 Sorteos cargados</h2>
      <ul>
        {lastSorteos.map((sorteo) => (
          <li key={sorteo.id}>Sorteo {sorteo.n_sorte} - {sorteo.fechasorteo}</li>
        ))}
      </ul>

      <h2>Añadir Sorteo</h2>
      <p></p>

      <form onSubmit={handleSubmit}>
        {/* Campo para el año */}
        <label className="form-label h5">Año:</label>
        <select name="ano" onChange={handleInputChange} value={sorteoData.ano}>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
          {/* Agrega más opciones según sea necesario */}
        </select>
      <p></p>
        {/* Campo para el mes */}
        <label className="form-label h5">Mes:</label>
        <select name="mes" onChange={handleInputChange} value={sorteoData.mes}>
          <option value="0">Enero</option>
          <option value="1">Febrero</option>
          <option value="2">Marzo</option>
          <option value="3">Abril</option>
          <option value="4">Mayo</option>
          <option value="5">Junio</option>
          <option value="6">Julio</option>
          <option value="7">Agosto</option>
          <option value="8">Septiembre</option>
          <option value="9">Octubre</option>
          <option value="10">Noviembre</option>
          <option value="11">Diciembre</option>
        </select>

        {/* Campo para la fecha de sorteo */}
        <p></p>
        
        <label className="form-label h5">Fecha de Sorteo:</label>
<input
  type="datetime-local"
  name="fechasorteo"
  onChange={handleInputChange}
  value={sorteoData.fechasorteo}
  required  // Agregado el atributo required
/>
<p></p>
        

{/* Campo para el último ingreso */}
<label  className="form-label h5">Último Ingreso:</label>
<input
  type="datetime-local"
  name="ultimoing"
  onChange={handleInputChange}
  value={sorteoData.ultimoing}
  required  // Agregado el atributo required
/>
<p></p>
        
<button className="btn btn-primary mt-4 text-white" type="submit">Agregar Sorteo</button>
      </form>
    </div>
  );
};

export default AddSorteoForm;
