// Section1.js
import React from 'react';
import Carrousel from '../../components/Carrousel'

const Section1 = () => {
  return (
    <>
      
      {/* Tu contenido aquí */}
      <Carrousel />
    </>
  );
};

export default Section1;