import React from 'react'

const DondeJuego = () => {
  return (
    <div className='container fondotransparente p-5'>
    
			<center><h1 classname="p-5 botonbordeazul text-white px-5 mx-5 py-3">¿Dónde Juego?</h1>	</center>
			
<p><strong>RECONOCIMIENTO DE AGENCIAS Y CORREDORES HABILITADOS.</strong> Aquí podrás encontrar las Agencias de Tómbola Habilitadas por Inprojuy. </p>



<div classname="container text-center p-3">
<iframe src="https://www.google.com/maps/d/embed?mid=1q8niJ8S1hXr5WfUrC41de8DF98AYLU0&ehbc=2E312F" width="90%" height="500vh"></iframe>

				</div>
    </div>
  )
}

export default DondeJuego