import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { daDK } from '@mui/x-data-grid/locales';
import nbNO from '@mui/x-data-grid/locales/nbNO';
import * as XLSX from 'xlsx';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext"; // Importa el contexto de autenticación

const UsuariosCupones = () => {
  const auth = useAuth(); // Obtén el contexto de autenticación
  const [userRole, setUserRole] = useState(null);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const url = 'https://us-central1-inprojuy-f1173.cloudfunctions.net/app/usuarios-cuponess';

    axios.get(url)
      .then((response) => {
        const updatedData = response.data.map((item, index) => ({
          rowNumber: index + 1,
          ...item
        }));
        setData(updatedData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al obtener los datos:', error);
        setLoading(false);
      });
  }, []);

  const columns = [
    { field: 'nombreApellido', headerName: 'Nombre y Apellido', width: 200 },
    { field: 'barrio', headerName: 'Barrio', width: 150 },
    { field: 'localidad', headerName: 'Localidad', width: 200 },
    { field: 'telefono', headerName: 'Teléfono', width: 130 },
    { field: 'cantidadCupones', headerName: 'Cantidad de Cupones', width: 180 },
    { field: 'rowNumber', headerName: 'Posición', width: 120 }, // Columna para el número de fila
  ];

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'UsuariosCupones');
    XLSX.writeFile(wb, 'usuarios-cupones.xlsx');
  };

  // Cargar el rol del usuario al cargar el componente
  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await axios.get(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`);
        setUserRole(response.data.role);
      } catch (error) {
        console.error('Error al obtener el rol del usuario:', error);
      }
    };
    fetchUserRole();
  }, [auth.id]);

  if (userRole !== "admin") {
    return (
      <Container style={{ height: 650, width: '100%' }}>
        <div style={{ height: 500, width: '100%' }}>
          <center>
            <h1 className='botonbordeazul p-2 text-white'>Acceso no autorizado</h1>
            <p>Usted no tiene permiso para acceder a esta página.</p>
          </center>
        </div>
      </Container>
    );
  }

  return (
    <Container style={{ height: 650, width: '100%' }}>
      <div style={{ height: 500, width: '100%' }}>
        <center><h1 className='botonbordeazul p-2 text-white'>Ranking de carga de Cupones</h1></center>
        <button onClick={exportToExcel}>Exportar a Excel</button> <a></a>
        <button className='red' onClick={() => navigate('/login')}>Volver</button>
        <p></p>

        {loading ? (
          <div id="load">
          <div>O</div>
          <div>D</div>
          <div>N</div>
          <div>A</div>
          <div>G</div>
          <div>R</div>
          <div>A</div>
          <div>C</div>
        </div>
        ) : (
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={pageSize}
            pagination
            onPageSizeChange={(params) => {
              setPageSize(params.pageSize);
            }}
          />
        )}
      </div>
    </Container>
  );
};

export default UsuariosCupones;
