import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { DataScroller } from 'primereact/datascroller';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Accordion, AccordionTab } from 'primereact/accordion';
import YoutubeViewer from './YoutubeViewer';
import Loading from '../loading/Loading';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

const UserDocumentsGrid = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [documents, setDocuments] = useState([]);
    const [videoId, setVideoId] = useState(null); // Estado para guardar el ID del video seleccionado
    const [displayModal, setDisplayModal] = useState(false); // Estado para controlar la visibilidad del modal
    const [loading, setLoading] = useState(true); // Estado para controlar la visibilidad del mensaje de carga

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const response = await axios.get(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/concursoresp/usuario/${auth.user}`);
                setDocuments(response.data);
                setLoading(false); // Ocultar el mensaje de carga una vez que los datos se hayan cargado
            } catch (error) {
                console.error('Error fetching documents:', error);
                setLoading(false); // Ocultar el mensaje de carga en caso de error
            }
        };
        fetchDocuments();
    }, [auth.user]);

    const handleViewVideo = (id) => {
        setVideoId(id); // Guardar el ID del video seleccionado
        setDisplayModal(true); // Mostrar el modal
    };

    const hideDialog = () => {
        setDisplayModal(false); // Ocultar el modal
        setVideoId(null); // Limpiar el ID del video seleccionado
    };

    const itemTemplate = (document) => {
        return (
            <div style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px', borderRadius: '5px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: '10px' }}>
                    <div><b>Escuela:</b> {document.escuela}</div>
                    <div><b>Usuario:</b> {document.usuario}</div>
                    <div><b>Fecha de Carga:</b> {document.fecha_carga}</div>
                    <div><b>Alumno o Curso:</b> {document.alumno_o_curso}</div>
                </div>
                <div style={{ marginBottom: '10px', display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                    <Button label="Ver Video" icon="pi pi-play" style={{ fontSize: '12px', padding: '6px 12px' }} onClick={() => handleViewVideo(document.id)} />
                    <Button label="Editar" icon="pi pi-pencil" style={{ fontSize: '12px', padding: '6px 12px' }} onClick={() => navigate(`/ConcursoRespEditForm/${document.id}`)} />
                    {document.pdfinscripcion && (
                        <Button label="Inscripción" icon="pi pi-file-pdf" style={{ fontSize: '12px', padding: '6px 12px' }} onClick={() => window.open(document.pdfinscripcion, '_blank')} />
                    )}
                </div>
                <div>
                    <Accordion>
                        {document.alumnos_participantes.map((alumno, index) => (
                            <AccordionTab key={index} header={`Alumno ${index + 1}: ${alumno.nombre_menor}`}>
                                <div><b>DNI:</b> {alumno.dni_menor}</div>
                                <div><b>Fecha de Nacimiento:</b> {alumno.fecha_nac_menor}</div>
                                {alumno.cesion && (
                                    <Button label="Ver Cesión" icon="pi pi-file-pdf" style={{ fontSize: '12px', padding: '6px 12px' }} onClick={() => window.open(alumno.cesion, '_blank')} />
                                )}
                            </AccordionTab>
                        ))}
                    </Accordion>
                </div>
            </div>
        );
    };

    return (
        <div className="datascroller-demo">
            {loading ? (
                <Loading />
            ) : (
                <div className="card" style={{ padding: '10px', boxSizing: 'border-box' }}>
                    <DataScroller value={documents} itemTemplate={itemTemplate} rows={5} buffer={0.4} />
                </div>
            )}

            {/* Modal para mostrar el visor de YouTube */}
            <Dialog 
                visible={displayModal} 
                onHide={hideDialog} 
                style={{ 
                    width: '80vw', 
                    maxWidth: '1000px',
                    backgroundColor: 'transparent', 
                    color: '#333', 
                    fontSize: '1.5rem', 
                    fontWeight: 'bold', 
                    border: 'none', 
                    cursor: 'pointer' 
                }}
                className="custom-dialog-class" 
                header={<span className="p-d-flex p-ai-center">Visor de Video</span>}
                footer={<Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />} 
            >
                {videoId && <YoutubeViewer videoId={videoId} />}
            </Dialog>
        </div>
    );
};

export default UserDocumentsGrid;
