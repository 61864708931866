import React, {useState} from 'react'
import { subirFotDenuncia, db } from "../firebase/firebase.config";
import { collection, addDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import enviarCorreo from "../firebase/enviarCorreo";


function Denuncias() {
  const [files, setFiles] = useState([null, null]);
  const [formValues, setFormValues] = useState({
    email: '',
    direccion: '',
    localidad: '',
    telefono: '',
    asistiallugar: false,
    aposte: false,
    recibicomprobante: false,
    direccionlugar:'',
    entrecalles:'',
    pagina:'',
    redsocial:'',
    otro:'',
  });

  const [sending, setSending] = useState(false); // State to track sending status

  const handleSubmit = async (e) => {
    e.preventDefault();

    const urls = await Promise.all(files.map(file => subirFotDenuncia(file)));
    try {
     
      setSending(true); // Set the state to true when starting the submission
      const formData = {
        id: uuidv4(),
        ...formValues,
        fotos: urls
      };

      await addDoc(collection(db, 'denuncias'), formData);
      
  const texto = `
  <h1>Denuncia</h1>><br>
  email:  ${formValues.email} <br>
  telefono:  ${formValues.telefono} <br>
  drieccion:  ${formValues.direccion} <br>
  Localidad:  ${formValues.localidad} <br>
  Asistí al Lugar:  ${formValues.asistiallugar} <br>
  Aposté:  ${formValues.aposte} <br>
  Recibí un Comprobante:  ${formValues.recibicomprobante} <br>
  Dirección del Lugar:  ${formValues.direccionlugar} <br>
  Entre calles:  ${formValues.entrecalles} <br>
  <h2>Fotos del lugar</h2>
  <center> <img src='${urls[0]}' className="centrar img-fluid adapt"/></center>
  <h2>En Linea</h2>
  Página web:  ${formValues.pagina} <br>
  Red Social:  ${formValues.redsocial} <br>
  Otro:  ${formValues.otro} <br>
  <h2>Foto del Sitio</h2>
  <center> <img src='${urls[1]}' className="centrar img-fluid adapt"/></center>
  <br>
  `
      await enviarCorreo('ricardoperezlindo@gmail.com','Formulario de Denuncia',texto).then(res=>{alert("mail enviado")
      console.log(res)})


      console.log('Datos guardados en la base de datos:', formData);

      setSending(false); // Set the state back to false when the submission is complete

      alert('Los datos se han cargado correctamente');
      window.location.href = 'https://www.inprojuy.gob.ar'; // Redireccionar a inprojuy.gob.ar
    } catch (error) {
      console.error(error);
      alert('Error al cargar los datos. Inténtalo de nuevo.');
      setSending(false); // Set the state back to false in case of an error

    }
  };


  const handleFileChange = (e, index) => {
    const newFiles = [...files];
    newFiles[index] = e.target.files[0];
    setFiles(newFiles);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: fieldValue
    }));
  };
 
  return (
    <div className='fondotransparente container'>
        <center><h2 className='botonbordeazul text-white px-5 py-3 mb-4'>Denuncias</h2></center>
        <p>Lo invitamos a completar el siguiente formulario ingresando la mayor cantidad posible de datos, y completando los campos obligatorios. </p>
 <p>El sistema de denuncias contra el juego clandestino del INPROJUY ha sido creado para que usted pueda canalizar las denuncias de manera anónima, asegurando la máxima confidencialidad.</p>

          <form  onSubmit={handleSubmit} className="contactform row g-3 mt-4">
        <div className='col-md-12 m-0 p-0'>
          <label className="h5 form-label">Correo Electrónico</label>
          <input type="email" className=" form-control border px-3 py-2 rounded placeholder-gray-500" id="exampleInputEmail1" aria-describedby="emailHelp" name ='email' value={formValues.email} onChange={handleChange}/>
          <label className="h5 form-label">Teléfono</label>
          <input type="tel" className=" form-control border px-3 py-2 rounded placeholder-gray-500" name ='telefono' value={formValues.telefono} onChange={handleChange} />
          <label className="h5 form-label">Dirección</label>
          <input type="text" className=" form-control border px-3 py-2 rounded placeholder-gray-500" name ='direccion' value={formValues.direccion} onChange={handleChange} />
          <label className="h5 form-label">Localidad</label>
          <input type="text" className=" form-control border px-3 py-2 rounded placeholder-gray-500" name ='localidad'value={formValues.localidad} onChange={handleChange}/>
        </div>
        <div className="mb-3 form-check">
          <input type="checkbox" className="form-check-input" id="exampleCheck1" name ='asistiallugar' checked={formValues.asistiallugar} onChange={handleChange} />
          <label className="form-check-label" for="exampleCheck1">Asistí al lugar</label>
        </div>
        <div className="mb-3 form-check">
          <input type="checkbox" className="form-check-input" id="exampleCheck1" name ='aposte' checked={formValues.aposte} onChange={handleChange}/>
          <label className="form-check-label" for="exampleCheck1">Aposté</label>
        </div>
        <div className="mb-3 form-check">
          <input type="checkbox" className="form-check-input" id="exampleCheck1" name ='recibicomprobante' checked={formValues.recibicomprobante} onChange={handleChange}/>
          <label className="form-check-label" for="exampleCheck1">Recibí un comprobante</label>
        </div>
        <div className='col-md-12 m-0 p-0'>
        <label className="h5 form-label">Dirección del lugar</label>
        <input type="text" className=" form-control border px-3 py-2 rounded placeholder-gray-500" name ='direccionlugar' value={formValues.direccionlugar} onChange={handleChange}/>
        <label className="h5 form-label">Entre calles/ Interseccion calle</label>
        <input type="text" className=" form-control border px-3 py-2 rounded placeholder-gray-500" name ='entrecalles'value={formValues.entrecalles} onChange={handleChange}/>
        <div className="input-group col-md-12 m-0">
          <label className="h5 col-md-12 form-label">Foto del lugar  </label>

          <input type='file' className="form-control  my-3 border px-3 py-2 rounded placeholder-gray-500" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"  onChange={(e) => handleFileChange(e, 0)} />
        </div>
        <label className="h5 form-label">EN LINEA:</label>
        <br/>
        <label className="h5 form-label">Pagina web</label>
        <input type="text" className=" form-control border px-3 py-2 rounded placeholder-gray-500" name ='pagina' value={formValues.pagina} onChange={handleChange}/>
        <label className="h5 form-label">Red Social</label>
        <input type="text" className=" form-control border px-3 py-2 rounded placeholder-gray-500" name ='redsocial' value={formValues.redsocial} onChange={handleChange} />
        <label className="h5 form-label">Otro</label>
        <input type="text" className=" form-control border px-3 py-2 rounded placeholder-gray-500" name ='otro' value={formValues.otro} onChange={handleChange} />
        <div className="input-group">
          <label className="h5 col-md-12 form-label">Foto del sitio  </label>
          <input type="file" className=" form-control  my-3 border px-3 py-2 rounded placeholder-gray-500" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" onChange={(e) => handleFileChange(e, 1)} />

        </div>
        <button className="btn btn-primary mt-4" type="submit" disabled={sending}>
          {sending ? "Enviando..." : "Subir Formulario"}
        </button>
</div>
      </form>
    </div>
  );
}

export default Denuncias