

import React from 'react'

const GuiaCobro = () => {
    return (
        <div className='text-left text-justify container fondotransparente3 p-5'>
        <center>
        <h1 className=" p-5 botonbordeazul text-white px-5 mx-5 py-3"> Guía Práctica para el Cobro de Premios</h1>
        </center>
  
        <br />
        <br />
  
        <div className='margenazul'>
            <br />
            <br />

            <p>
                <strong>Estimado/a Apostador/a:</strong>
            </p>
            <p>
                En cumplimiento de la normativa vigente en materia de prevención de lavado de activos y financiación del terrorismo,
                el INPROJUY exige que se obtenga, verifique y registre información que identifique a toda persona que resulte
                favorecida con un premio igual o superior a <strong>quince (15) salarios mínimos vitales y móviles</strong>.
            </p>
            <h4>Requisitos para Cobrar un Premio</h4>
            <p>Si has ganado un premio igual o superior a esta cifra, deberás:</p>
            <ul>
                <li>Dirigirte al INPROJUY con el cupón ganador.</li>
                <li>Presentar tu <strong>DNI</strong> y una copia del mismo.</li>
                <li>Proporcionar la <strong>constancia de CUIL</strong>.</li>
                <li>
                    Completar los formularios en carácter de <strong>declaración jurada</strong>, conforme a la normativa de la
                    Unidad de Información Financiera (UIF). Esto se realizará en el sector de Juegos de Agencia, donde se encargan
                    de completar la documentación y enviarla a la División de Lavado de Activos del Departamento de Auditoría y
                    Lavado de Activos para su control y posterior envío a Tesorería.
                </li>
            </ul>
            <p>¡Muchas gracias por tu colaboración!</p>
            <p>
                <em>Artículo 2 inciso B subinciso 1 de la Resolución UIF Nº 194/2023</em>
                <br />
                <em>Arts. 22 y 23 de la Resolución UIF Nº 194/2023</em>
            </p>
            <br />
        <br />
      </div>
    </div>

    )
}

export default GuiaCobro