import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FiAlignRight, FiXCircle } from "react-icons/fi";
import logo from './logonav.jpg';
import './Navbar.css';
import { useAuth } from "../../context/AuthContext";
import { RiRadioButtonLine } from "react-icons/ri";
import { Box } from '@mui/material';
import { Navbar } from 'react-bootstrap';

const NavBar = () => {
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const auth = useAuth();

  const toggleClass = () => {
    setisMenu(!isMenu);
    setResponsiveclose(!isResponsiveclose);
  };

  const toggleSubmenu = (menu) => {
    if (activeSubMenu === menu) {
      setActiveSubMenu(null);
    } else {
      setActiveSubMenu(menu);
    }
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push('menuq2');
  } else {
    boxClass.push('');
  }

  return (
    <Box component="header" className="header__middle">
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={{ width: '35%' }}>
          <NavLink exact activeClassName='is-active' to="/">
            <img className='logonav' src={logo} alt="logo" />
          </NavLink>
        </Box>
        <Box sx={{ width: '65%', backgroundColor: 'rgba(59, 160, 168, 1)' }}>
          <nav className="main-nav letra_menu">
            {isResponsiveclose ? (
              <span className="menubar__button" onClick={toggleClass}>
                <FiXCircle />
              </span>
            ) : (
              <span className="menubar__button" onClick={toggleClass}>
                <FiAlignRight />
              </span>
            )}
            <ul className={boxClass.join(' ')}>
              <li className="menu-item sub__menus__arrows">
                <Link to="#" onClick={() => toggleSubmenu('institucional')}>
                  Institucional
                </Link>
                <ul className={`sub__menus ${activeSubMenu === 'institucional' ? 'sub__menus__Active' : ''}`}>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/mision`}>
                      Misión, Visión, Objetivos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/autoridades`}>
                      Autoridades y Organigrama
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/delegaciones`}>
                      Delegaciones del interior
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="menu-item sub__menus__arrows">
                <Link to="#" onClick={() => toggleSubmenu('juegos')}>
                  Juegos
                </Link>
                <ul className={`sub__menus ${activeSubMenu === 'juegos' ? 'sub__menus__Active' : ''}`}>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/reglamentos`}>
                      Reglamentos y Leyes
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/agencias`}>
                      Agencias
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/descargas`}>
                      Descargas Agencias
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/dondejuego`}>
                      ¿Dónde Juego?
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/calendariojuegos`}>
                      Calendario de Juegos
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="menu-item sub__menus__arrows">
                <Link to="#" onClick={() => toggleSubmenu('juego_responsable')}>
                  Juego Responsable
                </Link>
                <ul className={`sub__menus ${activeSubMenu === 'juego_responsable' ? 'sub__menus__Active' : ''}`}>
                  {/*        <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/juegoResponsable`}>
                      Reglamentos y Leyes
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/juegoResponsable`}>
                      ¿Qué significa?
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/comotrabajamos`}>
                      ¿Cómo Trabajamos?
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/quetaljuego`}>
                      ¿Cómo estoy jugando?
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/mitosyverdades`}>
                      Mitos y Verdades
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/recomendaciones`}>
                      Recomendaciones
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/lugaresdeayuda`}>
                      Lugares de Ayuda
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/formulario`}>
                      Formularios de Autoexclusión
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="menu-item sub__menus__arrows">
                <Link to="#" onClick={() => toggleSubmenu('juego_ilegal')}>
                  Juego Ilegal
                </Link>
                <ul className={`sub__menus ${activeSubMenu === 'juego_ilegal' ? 'sub__menus__Active' : ''}`}>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/juegoilegal`}>
                      ¿Qué es el juego Ilegal?
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/RecomendacionesJuegoIlegal`}>
                      Recomendaciones
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/lavadodeactivos`}>
                      Prevención del lavado de activos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/denuncias`}>
                      Denuncias
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className="menu-item sub__menus__arrows">
                <Link to="#" onClick={() => toggleSubmenu('lavado_de_activos')}>
                  Lavado de Activos
                </Link>
                <ul className={`sub__menus ${activeSubMenu === 'lavado_de_activos' ? 'sub__menus__Active' : ''}`}>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/manualprocedimientos`}>
                      Manual de Política y Procedimiento
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/personasexpuestas`}>
                      ¿que son las personas expuestas politicamente?
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/porquedelaprevencion`}>
                    El Porqué de la Prevención
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/queesgafi`}>
                    ¿Que es el GAFI?
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/normativacomplementaria`}>
                    Normativa Complementaria
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/guiacobro`}>
                    Guia de Cobro
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="menu-item sub__menus__arrows">
                <Link to="#" onClick={() => toggleSubmenu('concursos')}>
                  Concurso
                </Link>
                <ul className={`sub__menus ${activeSubMenu === 'concursos' ? 'sub__menus__Active' : ''}`}>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/quienespueden`}>
                      ¿Cómo participar?
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/comocargarcupon`}>
                      Jugá X Jugar, SPOT
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/bases`}>
                      Bases y Condiciones
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleClass} activeClassName='is-active' to={`/premios`}>
                      Premios
                    </NavLink>
                  </li>
                  {auth.user && (
                    <li>
                      <Link to='/ConcursoResp'>Ingresar Video</Link>
                    </li>
                  )}
                </ul>
              </li>
              <li className="menu-item">
                <Link to="/login" className="nav-link luzhover letrachica">
                  {auth.user} <RiRadioButtonLine className="online" />
                </Link>
              </li>
              <li className="menu-item">
                {auth.user === "" ? (
                  <Link to="/login" className="fondotransparente2 p-2">
                    Ingresar
                  </Link>
                ) : (
                  <></>
                )}
              </li>
            </ul>
          </nav>
        </Box>
      </Box>
      <Box sx={{ width: '100%', height: '6px', backgroundColor: 'red', position: 'absolute', bottom: 0 }} />
    </Box>
  );
};

export default NavBar
