import React from 'react'

const PersonasExpuestas = () => {
    return (
        <div className='container fondotransparente p-5 my-5 '>
            <center><h1 className=" p-5 botonbordeazul text-white px-5 mx-5 py-3">¿Qué son las Personas Expuestas Políticamente (PEPs)?</h1></center>
            <br />
            <br />
            <p>
                Son individuos que ocupan o han ocupado altos cargos
                en la función pública, tanto a nivel nacional como internacional. Estos cargos pueden ser en el Poder Ejecutivo,
                Legislativo o Judicial, y las personas consideradas como PEPs incluyen no solo a los funcionarios en ejercicio,
                sino también a aquellos que dejaron sus funciones recientemente.
            </p>
            <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                            Definición y Alcance
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                        <div className="accordion-body">
                            <p>
                                Según la <strong>Resolución 134/2018</strong> de la <strong>Unidad de Información Financiera (UIF)</strong>, se
                                consideran PEPs a:
                            </p>
                            <ul>
                                <li>Funcionarios públicos de alto nivel a nivel nacional, provincial o municipal.</li>
                                <li>Funcionarios extranjeros que desempeñan funciones equivalentes en sus respectivos países.</li>
                                <li>Parientes cercanos, como cónyuges, padres, hijos y otros familiares hasta el segundo grado de consanguinidad.</li>
                            </ul>
                            <p>
                                Es importante destacar que un funcionario sigue siendo considerado PEP hasta <strong>dos años después</strong> de
                                haber dejado su cargo.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                            Ejemplos de PEPs
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                        <div className="accordion-body">
                            <p>
                                Algunos ejemplos de personas que se consideran PEPs son:
                            </p>
                            <ul>
                                <li>Presidentes, ministros, legisladores, jueces y otros altos funcionarios del gobierno.</li>
                                <li>Directores de empresas estatales o miembros de órganos directivos de partidos políticos importantes.</li>
                                <li>Personas con influencia significativa debido a su posición política.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                            Medidas de Control y Monitoreo
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                        <div className="accordion-body">
                            <p>
                                El INPROJUY debe aplicar medidas adicionales de control y monitoreo a las PEPs para prevenir el uso indebido de su
                                posición en actividades relacionadas con el lavado de dinero o la financiación del terrorismo.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonasExpuestas