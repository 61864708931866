import React from 'react';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Seccion3';  
import Section5 from './Section5';
import Contacto from '../../components/Contacto/Contacto';
import styled from 'styled-components';

const Container = styled.div`
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
  width: 93vw;
  /* Oculta la barra de desplazamiento pero permite el desplazamiento */
  -ms-overflow-style: none;  /* IE y Edge */
  scrollbar-width: none;  /* Firefox */
  
  &::-webkit-scrollbar {
    display: none;  /* Safari y Chrome */
  }
`;

const Section = styled.div`
  scroll-snap-align: start;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 1px; /* Espacio entre secciones en móviles */
  }
`;

const Inicio = () => {
  return (
    <Container>
      <Section>
        <Section1 />
      </Section>
      <Section>
        <Section2 />
      </Section>
      <Section>
        <Section3 />
      </Section>
      <Section>
        <Section5 />
      </Section>
      <Section>
        <Contacto />
      </Section>
    </Container>
  );
};

export default Inicio;
