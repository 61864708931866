import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, createTheme, ThemeProvider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Calendariodejuegos = () => {
    const theme = createTheme({
        palette: {
            background: {
                paper: '#fff',
            },
            text: {
                primary: '#45A8D3',
                secondary: '#1383B3',
            },
            action: {
                active: '#001E3C',
            },
            success: {
                main: '#009688',  // Ajuste correcto para la paleta de colores
            },
        },
    });

    const games = [
        {
            title: 'TÓMBOLA',
            img: './img/TOMBOLA.jpg',
            subGames: [
                { title: 'Previa', img: './img/TOMBOLA_PREVIA.jpg' },
                { title: 'Primera', img: './img/TOMBOLA_PRIMERA.jpg' },
                { title: 'Matutina', img: './img/TOMBOLA_MATUTINA.jpg' },
                { title: 'Vespertina', img: './img/TOMBOLA_VESPERTINA.jpg' },
                { title: 'Nocturna', img: './img/TOMBOLA_NOCTURNA.jpg' },
            ]
        },
        { title: 'QUINI 6', img: './img/QUINISORTEO.jpg' },
        { title: 'BRINCO', img: './img/BRINCOSORTEO.jpg' },
        { title: 'LOTERIA', img: './img/LOTERIASORTEO.jpg' },
        { title: 'LOTO 5', img: './img/LOTO5SORTEO.jpg' },
        { title: 'LOTO PLUS', img: './img/LOTOPLUSSORTEO.jpg' },
        { title: 'POZO DE LA QUINIELA', img: './img/QUINIELASORTEO.jpg' },
        { title: 'TOMBO-EXPRESS', img: './img/TOMBOEXPRESSSORTEO.jpg' },
        { title: 'TELEKINO', img: './img/TELEKINO.jpg' },
        { title: 'BETWARRIOR', img: './img/BETWARRIOR.jpg' }
    ];

    return (
        <ThemeProvider theme={theme}>
            <div className='container my-5 fondotransparente'>
                <center><h1 className="p-5 botonbordeazul text-white px-5 mx-5 py-3">Calendario de Juegos</h1></center> <p></p>
                {games.map((game, index) => (
                    <Accordion
                        key={index}
                        sx={{
                            borderRadius: 2,
                            p: 1,
                            boxShadow: 3,
                     
                            '&:not(:last-child)': { marginBottom: 2 }
                        }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            sx={{ justifyContent: 'center', textAlign: 'center' }}>
                            <Typography sx={{ width: '100%', fontSize: 20,  textAlign: 'center' }}>
                                <strong>{game.title}</strong>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {game.subGames ? (
                                game.subGames.map((subGame, subIndex) => (
                                    <Accordion
                                        key={subIndex}
                                        sx={{ boxShadow: 2, '&:not(:last-child)': { marginBottom: 2 } }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`subPanel${subIndex}-content`}
                                            id={`subPanel${subIndex}-header`}
                                            sx={{ justifyContent: 'center', textAlign: 'center' }}>
                                            <Typography sx={{ width: '100%', textAlign: 'center' }}>
                                                <strong>{subGame.title}</strong>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <img src={subGame.img} className="centrar img-fluid my-3" alt={subGame.title} />
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            ) : (
                                <img src={game.img} className="centrar img-fluid my-3" alt={game.title} />
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </ThemeProvider>
    );
};

export default Calendariodejuegos;
