import React from 'react'

const RecomendacionesJuegoIlegal = () => {
  return (
    <div className='container-fluid py-5 fondotransparente5'>
      <div className='margenazul pb-1'>
      <h2 className='botonbordeazul text-white px-5 py-3 mb-4'>Recomendaciones
      sobre el Consumo Problemático y el Juego Ilegal en la Era Digital</h2>
      <br/>
      <center>
        {<div className=''>
          {/* Media query para dispositivos móviles */}
          <img
            className='img-fluid mobile-image'
            src='./img/sivasajugar.jpeg'
            alt='Imagen para dispositivos móviles'
          />

          {/* Media query para escritorio */}
          <img
            className='img-fluid desktop-image'
            src='./img/sivasajugar.jpeg'
            alt='Imagen para escritorio'
          />
        </div>}
      </center>
      <p>El acceso a las tecnologías de la información y la comunicación (TIC), como Internet y las redes sociales, ofrece múltiples beneficios, pero también plantea riesgos, especialmente en relación con el juego problemático y el juego ilegal. En este contexto, es fundamental educar a los adolescentes y a sus familias para fomentar el uso responsable y reducir las posibilidades de exposición a entornos no regulados de apuestas en línea. A continuación, presentamos algunas recomendaciones prácticas para padres y educadores:</p>
      <br/>
    </div>
      <div className='margenrojo jus'>
      <ul>
        <li>1. Establezca límites en el uso de dispositivos electrónicos Tiempo y lugar: Defina horarios específicos para el uso de Internet y evite que los adolescentes usen dispositivos en la privacidad de su habitación, donde la supervisión es limitada. Prohibiciones específicas: Inste a no acceder a sitios de apuestas o a descargar aplicaciones de apuestas no autorizadas. Explique los riesgos del juego ilegal, como la falta de protección y el aumento del riesgo de adicción. EN LAS PLATAFORMAS LEGALES LOS MENORES NO APUESTAN !!!! LAS PLATAFORMAS LEGALES TIENEN LA TERMINACION BET.AR</li>
        <br/>
        <li>2. Promueva el diálogo y la educación sobre el juego y los riesgos online Información y comunicación: Hablar abiertamente sobre los efectos negativos del juego problemático. Puede ayudar observar series, noticias o documentales que aborden estos temas, y aproveche estos momentos para transmitir valores y fortalecer el pensamiento crítico Y REFLEXIVO sobre temas como la publicidad engañosa. Consumo problemático: Identifique signos de uso excesivo de dispositivos y explore, junto al adolescente, cómo esto afecta su vida cotidiana, emociones y relaciones. Ayúdelo a reconocer cuándo el uso de tecnologías se vuelve excesivo.</li>
        <br/>
        <li>3. Fomente actividades alternativas Aficiones y deportes: Potencie la participación en actividades deportivas, artísticas y culturales. Estas ayudan a reducir la dependencia digital y fortalecen la autoestima, la socialización y la conexión con el mundo físico. Trabajo en equipo: Impulse actividades en grupo que favorezcan las habilidades interpersonales y disminuyan la preferencia por el aislamiento en entornos digitales.</li>
        <br/>
        <li>4. Establezca un entorno seguro de navegación Supervisión y control: Coloque filtros en los dispositivos que utilizan sus hijos y revise las clasificaciones de videojuegos. Navegue y juegue ocasionalmente con ellos para entender sus intereses y transmitir confianza. Conciencia sobre riesgos: Explique la importancia de no compartir información personal en plataformas no seguras o en redes de contactos desconocidos. La buena comunicación en la familia es clave para que los adolescentes pidan ayuda cuando lo necesiten.</li>
        <br/>
        <li>5. Promueva la educación en valores y habilidades sociales. Modelo positivo: Su propio comportamiento online es fundamental para educar. sea un buen ejemplo. Comparta y modele un uso  responsable de las TIC. Autoestima y espíritu crítico: Ayude al adolescente a desarrollar la capacidad de cuestionar y discernir entre contenido adecuado y no adecuado, facilitando la toma de decisiones informadas en el ámbito digital y en relación con el juego. Estas prácticas ayudan a que los adolescentes no solo se protejan de riesgos asociados con el juego problemático y el uso inadecuado de las TIC, sino que también les facilitan herramientas para una vida digital saludable y equilibrada.</li>
      </ul>
      <br/>

      <p>Señales de alarma que pueden indicar un consumo problemático de tecnologías o juegos de azar. Detectarlas a tiempo es clave para intervenir y prevenir problemas mayores:</p>

<ul>
        <li>1. Aumento en el tiempo de uso La persona pasa cada vez más tiempo conectada o jugando, y es incapaz de reducir ese tiempo, a pesar de intentarlo. Necesita conectarse o jugar durante períodos prolongados para sentir el mismo nivel de satisfacción (tolerancia).</li>
        <br/>
        <li>2. Impacto en la vida cotidiana Se descuidan responsabilidades escolares, laborales o familiares debido al tiempo dedicado a las tecnologías o juegos. La persona disminuye o evita la interacción cara a cara y muestra desinterés por actividades que solía disfrutar, como el deporte o salidas con amigos.</li>
        <br/>
        <li>3. Irritabilidad y ansiedad al no estar conectado Se muestra irritable, ansioso o deprimido cuando no puede acceder a Internet, redes sociales o plataformas de juego. Si está en un entorno sin conexión (como durante un vuelo), se observa nervioso o impaciente.</li>
        <br/>
        <li>4. Uso como escape emocional. Utiliza las tecnologías o los juegos como una vía de escape para evitar problemas o gestionar emociones negativas, como estrés, tristeza o soledad. La persona puede recurrir a dispositivos o plataformas de juego en momentos de angustia, buscando alivio temporal.</li>
        <br/>
        <li>5. Deterioro en las relaciones interpersonales Se observa un distanciamiento de familiares y amigos, y dificultades para comunicarse de forma directa. Los familiares o amigos suelen expresar preocupación sobre el tiempo que pasa en línea o jugando, y la persona se molesta o se niega a hablar del tema.</li>
        <br/>
        <li>6. Problemas financieros o laborales Gasta dinero en juegos de azar o microtransacciones en juegos online sin medir el impacto económico. Descubre que pierde oportunidades laborales o su rendimiento en el trabajo o estudios se ve afectado debido a su consumo de tecnologías o apuestas.</li>
        <br/>
        <li>7. Negación del problema Aunque la persona reconoce que su consumo es excesivo, lo justifica como algo “normal” o necesario, minimizando las consecuencias. Niega o se irrita cuando alguien menciona el tema, evitando la conversación o el reconocimiento de las señales de advertencia.</li>
        <br/>
      </ul>

      <p>Si estos comportamientos aparecen de forma reiterada, es importante buscar ayuda profesional para evaluar la situación y tomar medidas preventivas.Es fundamental abordar el consumo problemático de tecnología o juegos de azar con sensibilidad, evitando caer en la autodiagnosticación o el autoestigma. Las personas afectadas pueden sentir vergüenza o culpa, lo que a menudo agrava la situación e impide que busquen ayuda. Tampoco es beneficioso que los familiares interpreten estas conductas de manera simplista o recurran al regaño como primera reacción; en su lugar, es más efectivo ofrecer apoyo y comprensión. Mantener el diálogo abierto y sin juicios es clave para acompañar a la persona en el proceso de cambio. Reconocer la necesidad de ayuda profesional y crear un entorno seguro puede ser crucial para una recuperación efectiva y sostenida. podes llamar al 0800  o buscar las unidades de salud mental mas cerca de tu domicilio. </p>
      <br/>
      </div>
      <center>
        {<div className=''>
          {/* Media query para dispositivos móviles */}
          <img
            className='img-fluid mobile-image'
            src='./img/jugasensitiosbet.jpeg'
            alt='Imagen para dispositivos móviles'
          />

          {/* Media query para escritorio */}
          <img
            className='img-fluid desktop-image'
            src='./img/jugasensitiosbet.jpeg'
            alt='Imagen para escritorio'
          />
        </div>}
      </center>
    </div>
  )
}

export default RecomendacionesJuegoIlegal