import { db} from "./firebase.config";
import {collection,addDoc} from "firebase/firestore"
export default async function enviarCorreo (email,subject,body){
    const collectionRef = collection(db,'mail');
    const emailContent={
        to: email,
        message: {
            subject: subject,
            text: body,
            html: `<p>${body}</p>`

        }
        
    };
    return await addDoc(collectionRef,emailContent);
}