import React, { useState, useEffect } from "react";
import axios from "axios";
import "jspdf-autotable";

function DataFetcher() {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios("https://us-central1-inprojuy-f1173.cloudfunctions.net/app/concurso");
      setData(result.data);
    };
    fetchData();
  }, []);

  if (!data[currentIndex]) {
    return <div>Cargando...</div>;
  }

  const { nombre, apellido, barrio, direccion, localidad, telefono, fechaNacimiento, nombrePadre, apellidoPadre, dniPadre, dni, fotos, ...rest } = data[currentIndex];

  const firstImageUrl = fotos.length > 0 ? fotos[0] : null; // Obtener la primera imagen o null si no hay imágenes.

  // Función para calcular la edad en años
  const calcularEdad = (fechaNacimiento) => {
    const nacimiento = new Date(fechaNacimiento);
    const hoy = new Date();
    let edad = hoy.getFullYear() - nacimiento.getFullYear();
    const mes = hoy.getMonth() - nacimiento.getMonth();
    if (mes < 0 || (mes === 0 && hoy.getDate() < nacimiento.getDate())) {
      edad--;
    }
    return edad;
  };

  const edadActual = calcularEdad(fechaNacimiento);

  return (
    <div className="container">
      <center>
        <button className="btn btn-primary" onClick={() => setCurrentIndex(currentIndex - 1)} disabled={currentIndex === 0}>Anterior</button> <a> </a>
        <button className="btn btn-primary" onClick={() => setCurrentIndex(currentIndex + 1)} disabled={currentIndex === data.length - 1}>Siguiente</button>
        <div>{currentIndex + 1} de {data.length}</div>
      </center>
      <center><h1 className='botonbordeazul text-white px-5 py-3 mb-4'>{nombre} {apellido}</h1></center>
     
      <div>
      <center><label className="h4 form-label">Edad: {edadActual} años</label></center>
        {firstImageUrl && (
          <img src={firstImageUrl} alt="Imagen" style={{ width: '70%', display: 'block', margin: 'auto', marginBottom: '20px' }} />
        )}
        <h3>Datos Personales:</h3>
        <p className="h4 form-label">Dirección: {direccion}</p>
        <p className="h4 form-label">Barrio: {barrio}</p>
        <p className="h4 form-label">Localidad: {localidad}</p>
        <p className="h4 form-label">Fecha de nacimiento: {fechaNacimiento}</p>
        
        <p className="h4 form-label">DNI: {dni}</p>
        <p className="h4 form-label">Telefono: {telefono}</p>
        <p className="h4 form-label">Nombre del Padre: {nombrePadre}</p>
        <p className="h4 form-label">Apellido del Padre: {apellidoPadre}</p>
        <p className="h4 form-label">DNI del padre: {dniPadre}</p>
        <p></p>

        {Object.entries(rest).map(([key, value]) => (
          <p key={key}>
            {key}: {String(value)}
          </p>
        ))}
      </div>
    </div>
  );
}

export default DataFetcher;
