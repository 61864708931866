import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import axios from 'axios';

function SearchRolesByEmail() {
  const auth = useAuth();
  const [emailInput, setEmailInput] = useState(""); // Nuevo estado para el correo electrónico
  const [userDataByEmail, setUserDataByEmail] = useState(null); // Nuevo estado para almacenar los datos del usuario por correo electrónico
  const [selectedRole, setSelectedRole] = useState(""); // Nuevo estado para manejar la selección del nuevo rol

  const handleEmailInputChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handleSearchByEmail = async () => {
    try {
      const response = await axios.get(
        `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/email/${emailInput}`
      );
      setUserDataByEmail(response.data);
    } catch (error) {
      console.error("Error al buscar usuario por correo electrónico:", error);
    }
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const updateRole = async () => {
    try {
      if (userDataByEmail && userDataByEmail.rol !== "admin") {
        await axios.post(
          `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${userDataByEmail.id}/role`,
          { newRole: selectedRole }
        );
        alert("Rol actualizado exitosamente");
      } else {
        alert("No tienes permisos para cambiar el rol a un admin");
      }
    } catch (error) {
      console.error("Error al actualizar el rol:", error);
      alert("Error al actualizar el rol");
    }
  };

  return (
    <div className="">
      <center>
        <h1 className="botonbordeazul text-white px-5 mx-5 py-3 h1">
          Búsqueda de Roles por Email
        </h1>
      </center>

      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <label style={{ fontSize: '1.5em' }}>Buscar Usuario por E-mail:</label>
        <input
          type="text"
          value={emailInput}
          onChange={handleEmailInputChange}
          className="form-control border py-2 rounded placeholder-gray-500"
        />
        <p></p>
        <button
          type="button"
          className="button"
          onClick={handleSearchByEmail}
        >
          Buscar por E-mail
        </button>
        <p></p>
      </div>

      {userDataByEmail && (
        <div style={{ marginTop: '20px' }}>
          <h3>Usuario Encontrado:</h3>
          <p>Nombre: {userDataByEmail.nombre}</p>
          <p>Apellido: {userDataByEmail.apellido}</p>
          <p>Rol actual: {userDataByEmail.rol}</p>

          <div style={{ marginTop: '10px' }}>
            <label style={{ fontSize: '1.5em' }}>Seleccionar Rol:</label>
            <select value={selectedRole} onChange={handleRoleChange} className="form-control">
              <option value="">Seleccione un Rol</option>
              <option value="agente">Agente</option>
              <option value="adminauto">Admin Autoexcluidos</option>
              <option value="user">Usuario</option>
              <option value="escuela">Escuela</option>
              <option value="jurado">Jurado</option>

            </select>
          </div>

          <button
            type="button"
            className="btn btn-success"
            onClick={updateRole}
            disabled={!selectedRole}
            style={{ marginTop: '10px' }}
          >
            Actualizar Rol
          </button>
        </div>
      )}

    </div>
  );
}

export default SearchRolesByEmail;
