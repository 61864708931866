import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { CircularProgress } from '@mui/material';
import { subirPdfInscripcion, subirPdfcesion } from '../../firebase/firebase.config';

const ConcursoResp = () => {
    const auth = useAuth();
    const [cesion, setCesion] = useState([]);
    const [inscripcion, setInscripcion] = useState(null);
    const [loading, setLoading] = useState(false);

    const initialFormData = {
        usuario: auth.user,
        link: '',
        escuela: '',
        alumno_o_curso: '',
        alumnos_participantes: [{ dni_menor: '', nombre_menor: '', fecha_nac_menor: '', cesion: null }],
        pdfinscripcion: ''
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAlumnosChange = (index, e) => {
        const { name, value } = e.target;
        const updatedAlumnos = formData.alumnos_participantes.map((alumno, i) =>
            i === index ? { ...alumno, [name]: value } : alumno
        );
        setFormData({ ...formData, alumnos_participantes: updatedAlumnos });
    };

    const handleCesionChange = (index, e) => {
        const file = e.target.files[0];
        const updatedAlumnos = formData.alumnos_participantes.map((alumno, i) =>
            i === index ? { ...alumno, cesion: file } : alumno
        );
        setFormData({ ...formData, alumnos_participantes: updatedAlumnos });
    };

    const addAlumno = () => {
        setFormData({
            ...formData,
            alumnos_participantes: [...formData.alumnos_participantes, { dni_menor: '', nombre_menor: '', fecha_nac_menor: '', cesion: null }]
        });
    };

    const removeAlumno = (index) => {
        const updatedAlumnos = formData.alumnos_participantes.filter((alumno, i) => i !== index);
        setFormData({ ...formData, alumnos_participantes: updatedAlumnos });
    };

    const handleinscripcionPdfChange = (e) => {
        const file = e.target.files[0];
        setInscripcion(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const fecha_carga = new Date().toLocaleString('es-AR', { timeZone: 'America/Argentina/Buenos_Aires' });

        try {
            let pdfUrl = null;
            if (inscripcion) {
                const validSize = 500 * 1024; // 500KB

                if (inscripcion.size > validSize) {
                    alert('El archivo PDF debe tener un tamaño menor a 500KB');
                    setLoading(false);
                    return;
                }

                pdfUrl = await subirPdfInscripcion(inscripcion);
            }

            // Upload cesion files for each student
            const alumnos_con_urls = await Promise.all(
                formData.alumnos_participantes.map(async (alumno) => {
                    if (alumno.cesion) {
                        const validSize = 500 * 1024; // 500KB
                        if (alumno.cesion.size > validSize) {
                            throw new Error(`El archivo PDF de cesión de ${alumno.nombre_menor} debe tener un tamaño menor a 500KB`);
                        }
                        alumno.cesion = await subirPdfcesion(alumno.cesion);
                    }
                    return alumno;
                })
            );

            await axios.post('https://us-central1-inprojuy-f1173.cloudfunctions.net/app/concursoresp', {
                ...formData,
                alumnos_participantes: alumnos_con_urls,
                fecha_carga,
                pdfinscripcion: pdfUrl
            });

            alert('Documento cargado exitosamente');
            setFormData(initialFormData); // Limpiar el formulario
        } catch (error) {
            console.error('Error al crear el documento:', error);
            alert('Error al crear el documento: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(255, 255, 255, 0.8)', zIndex: 9999 }}>
                    <CircularProgress />
                    <span style={{ marginLeft: '10px' }}>Cargando...</span>
                </div>
            )}
            <center><h4 className='b px-5 py-3 mb-4'>Cargar Video para el Concurso</h4></center>

            <div>
                <label className="h5 form-label">Link:</label>
                <input className="form-control col-6" type="text" name="link" value={formData.link} onChange={handleInputChange} required />
            </div>
            <div>
                <label className='h5 form-label'>Escuela:</label>
                <input className="form-control col-6" type="text" name="escuela" value={formData.escuela} onChange={handleInputChange} required />
            </div>
            <div>
                <label className='h5 form-label'>Alumno o Curso:</label>
                <input className="form-control col-6" type="text" name="alumno_o_curso" value={formData.alumno_o_curso} onChange={handleInputChange} required />
            </div>

            <div>
                <label className="h5 col-md-12 form-label">Formulario PDF (hasta 500KB)</label>
                <input required type="file" className="form-control col-6" onChange={handleinscripcionPdfChange} />
            </div>

            <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                <label className='h4 form-label'>Alumnos Participantes:</label>
                {formData.alumnos_participantes.map((alumno, index) => (
                    <div key={index} style={{ marginTop: '20px' }}>
                        <label className="h5 form-label">Alumno {index + 1}:</label>
                        <p></p>
                        <button className="eliminar-alumno" type="button" onClick={() => removeAlumno(index)}>Eliminar Alumno {index + 1}</button>
                        <p></p>
                        <label className="h5 form-label">DNI:</label>
                        <input
                            className="form-control col-6"
                            type="text"
                            name="dni_menor"
                            placeholder="DNI del menor"
                            value={alumno.dni_menor}
                            onChange={(e) => handleAlumnosChange(index, e)}
                            required
                        />
                        <label htmlFor="nombre" className="h5">Nombre:</label>
                        <input
                            className="form-control col-6"
                            type="text"
                            name="nombre_menor"
                            placeholder="Nombre del menor"
                            value={alumno.nombre_menor}
                            onChange={(e) => handleAlumnosChange(index, e)}
                            required
                        />
                        <label htmlFor="nombre" className="h5">Fecha de Nacimiento:</label>
                        <input
                            className="form-control col-6"
                            type="date"
                            name="fecha_nac_menor"
                            placeholder="Fecha de Nacimiento del menor"
                            value={alumno.fecha_nac_menor}
                            onChange={(e) => handleAlumnosChange(index, e)}
                            required
                        />
                        <label className="h5 form-label">Nota de Cesión (hasta 500KB):</label>
                        <input
                            type="file"
                            className="form-control col-6"
                            onChange={(e) => handleCesionChange(index, e)}
                            required
                        />
                    </div>
                ))}
                <button className="agregar-alumno" style={{ marginTop: '20px' }} type="button" onClick={addAlumno}>Agregar Alumno</button>
            </div>

            <div style={{ marginTop: '30px' }}>
                <button type="submit" disabled={loading}>Cargar Video</button>
            </div>
        </form>
    );
};

export default ConcursoResp;
