import React, { useState, useEffect } from "react";
import { Container, Table, Image, Modal, Button } from 'react-bootstrap';
import Switch from '@mui/material/Switch';
import { Link } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import enviarCorreo from "../../firebase/enviarCorreo";



const AutoexcluidosDetalle = ({ autoexcluidoData, showModal, modalImageSrc, handleModalShow, handleModalClose }) => {
  const formatFechaHora = (marcaDeTiempo) => {
    const fecha = new Date(marcaDeTiempo);
    const opciones = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'UTC', // Establecer la zona horaria a UTC
    };
    return new Intl.DateTimeFormat('es-AR', opciones).format(fecha);
  };

  const renderValorAnterior = (valorAnterior) => {
    if (typeof valorAnterior === 'boolean') {
      return valorAnterior ? 'Sí' : 'No';
    } else if (typeof valorAnterior === 'string') {
      if (/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(valorAnterior)) {
        return (
          <span
            className="link"
            onClick={() => handleModalShow(valorAnterior)}
          >
            Ver valor anterior
          </span>
        );
      } else {
        return <span>{valorAnterior}</span>;
      }
    } else {
      return <span>{valorAnterior}</span>;
    }
  };
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const [loading, setLoading] = useState(false);


  const auth = useAuth();
  const registerChange = async (tipo) => {
    try {
      setLoading(true);
      // Verifica que haya un usuario autenticado y autoexcluidoData no sea null
      if (auth.user && autoexcluidoData && autoexcluidoData.id) {
        const cambio = {
          tipo,
          marcaDeTiempo: new Date().toISOString(),
          usuario: auth.user,
        };

        // Crea una copia del array actual de cambios y agrega el nuevo cambio
        const cambios = [...autoexcluidoData.cambios, cambio];

        // Envia el cambio al servidor y actualiza el estado local después del éxito
        await axios.post(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/modiautoexcluidos/${autoexcluidoData.id}`, {
          cambios: cambios,
          // Resto de los datos que necesitas enviar
        });

        // Actualiza el estado local después del éxito del servidor
        //setAutoexcluidoData((prevData) => ({
        //  ...prevData,
        //  cambios: cambios,
        //}));

        // Indica que el registro fue exitoso

        const hoy = new Date();
        const texto = `
        <h3 style="text-align: justify;">Sr Operador:<br /><br /><br />Le informamos que el día ${formatFechaHora(hoy).split(',')[0]}, el SRA./SR ${autoexcluidoData.nombre} , DNI ${autoexcluidoData.dni}, Auto excluido Asistió a una sala de juego  con la finalidad de ingresar a jugar.
        Por lo que se les da aviso para que tomen los recaudos necesarios.</h3>
        <h3>Sin otro particular saludo a Usted, muy atentamente.</h3>
        `;

        fetch('https://us-central1-inprojuy-f1173.cloudfunctions.net/app/automails')
          .then(response => response.json())
          .then(data => {
            // Obtener la lista de correos
            const listaCorreos = data.map(item => item.email);
            console.log(listaCorreos);

            // Enviar correo a cada correo de la lista         enviarCorreo('comunicacion@inprojuy.gob.ar',asunto,texto).then(res=>{alert("mail enviado")
            listaCorreos.forEach(correo => {
              //alert('Enviando correo a: '+ correo);
              enviarCorreo(correo, 'Intento de Ingreso', texto)
                .then(res => {
                  console.log('Correo enviado correctamente:', res);

                })
                .catch(error => {
                  console.error('Error al enviar correo:', error);
                  alert("Error al enviar correo a: " + correo);
                });
            });




          })
          .catch(error => console.error('Error:', error));

        setRegistroExitoso(true);

      }
    } catch (error) {
      console.error("Error registrando intento", error);
    }
    finally {

      alert("Correo enviado correctamente");
      setLoading(false); // Desbloquea el botón y oculta la ruedita de carga

    }
  };


  const renderValorActual = (valorActual) => {
    if (typeof valorActual === 'boolean') {
      return valorActual ? 'Sí' : 'No';
    } else if (typeof valorActual === 'string') {
      if (/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(valorActual)) {
        return (
          <span
            className="link"
            onClick={() => handleModalShow(valorActual)}
          >
            Ver valor actual
          </span>
        );
      } else {
        return <span>{valorActual}</span>;
      }
    } else {
      return <span>{valorActual}</span>;
    }
  };

  return (
    <div className="container-fluid py-5 fondotransparente5">
      <Container style={{ height: '100%', width: '100%' }}>
        <div style={{ height: '100%', width: '100%' }}>
          <center>
            <h2 className="botonbordeazul text-white px-5 py-3 mb-4">Detalle del Autoexcluido</h2>
          </center>
          <br />
          {autoexcluidoData && (
            <>
              <hr />
              <div style={{ textAlign: 'center' }}>
                <Image
                  src={
                    autoexcluidoData.fotos.filter((foto) => foto !== null).pop() || ''
                  }
                  alt={`Foto de ${autoexcluidoData.nombre}`}
                  fluid
                  style={{
                    width: '300px',
                    height: '300px',
                    objectFit: 'cover',
                  }}
                  onClick={() =>
                    handleModalShow(
                      autoexcluidoData.fotos.filter(
                        (foto) => foto !== null
                      ).pop() || ''
                    )
                  }
                />
              </div>
              <ul style={{ fontSize: '1.2em' }}>
                <h4>Nombre: {autoexcluidoData.nombre}</h4>
                <h5>DNI: {autoexcluidoData.dni}</h5>
                <h5>Email: {autoexcluidoData.mail}</h5>
                <h5>Domicilio: {autoexcluidoData.domicilio}</h5>
                <h5>Nacionalidad: {autoexcluidoData.nacionalidad}</h5>
                <h5>Teléfono: {autoexcluidoData.telefono}</h5>
                <h5>Teléfono de Apoyo: {autoexcluidoData.telefonoApoyo}</h5>
                <h5>Ocupación: {autoexcluidoData.ocupacion}</h5>
                <h5>Localidad: {autoexcluidoData.localidad}</h5>
                <h5>Fecha de Nacimiento: {autoexcluidoData.fechaNacimiento}</h5>
                <h5>Activo: <Switch checked={autoexcluidoData.activo} /></h5>
                <h5>Fecha de Alta: {(autoexcluidoData.alta.split('T')[0])}</h5>
                <h5>Fecha de Vencimiento: {autoexcluidoData.vencimiento.split('T')[0]}</h5>
                <h5>Lugar de Entrega: {autoexcluidoData.lugarEntrega}</h5>
              </ul>

              {autoexcluidoData.pdfDocument && autoexcluidoData.pdfDocument.length > 0 && (
                <>
                  <h3>Documentos Adjuntos:</h3>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Documento</th>
                        <th>Pdf</th>
                      </tr>
                    </thead>
                    <tbody>
                      {autoexcluidoData.pdfDocument.map((documento, index) => (
                        <tr key={index}>
                          <td>{documento.nombredoc}</td>
                          <td>
                            <a href={documento.url} target="_blank" rel="noopener noreferrer">
                              Ver PDF
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}

              {autoexcluidoData.observaciones && autoexcluidoData.observaciones.length > 0 && (
                <>
                  <h3>Observaciones:</h3>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Observación</th>
                      </tr>
                    </thead>
                    <tbody>
                      {autoexcluidoData.observaciones.map((observacion, index) => (
                        <tr key={index}>
                          <td>{(observacion.fecha.split('T')[0])}</td>
                          <td>{observacion.observacion}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}

              {autoexcluidoData.cambios && autoexcluidoData.cambios.length > 0 && (
                <>
                  <h3>Historial de Cambios:</h3>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Tipo de Cambio</th>
                        <th>Fecha y Hora</th>
                        <th>Usuario</th>
                        <th>Valor Anterior</th>
                        <th>Valor Actual</th>
                      </tr>
                    </thead>
                    <tbody>
                      {autoexcluidoData.cambios.map((cambio, index) => (
                        <tr key={index}>
                          <td>{cambio.tipo}</td>
                          <td>{formatFechaHora(cambio.marcaDeTiempo)}</td>
                          <td>{cambio.usuario}</td>
                          <td>{renderValorAnterior(cambio.valorAnterior)}</td>
                          <td>{renderValorActual(cambio.valorActual)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}

              <button >
                <Link to={`/ModificarAutoexcluido/${autoexcluidoData.dni}`}>Editar</Link>
              </button>
              <p></p>
              <button
                onClick={() => registerChange("Asistencia a lugar de juego")}
                disabled={loading}
              >
                {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : "Registrar Asistencia a Agencia o Sala de Juego"}
              </button>



            </>
          )}
        </div>
      </Container>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Imagen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image src={modalImageSrc} alt="Imagen" fluid />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AutoexcluidosDetalle;
