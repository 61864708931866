import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Rating } from 'primereact/rating';
import { useAuth } from '../../context/AuthContext';

const YoutubeViewer = ({ videoId }) => {
    const auth = useAuth();
    const [userRole, setUserRole] = useState(null);
    const [loading, setLoading] = useState(true);
    const [videoInfo, setVideoInfo] = useState(null);
    const [error, setError] = useState(null);
    const [canVote, setCanVote] = useState(false);
    const [userRating, setUserRating] = useState(null);
    const [averageRating, setAverageRating] = useState(null);

    const extractVideoId = (url) => {
        const urlObj = new URL(url);
        return urlObj.searchParams.get('v');
    };

    useEffect(() => {
        console.log('El videoId es', videoId); // Verificar que videoId está llegando correctamente

        const fetchVideoInfo = async () => {
            try {
                const response = await axios.get(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/concursoresp/${videoId}`);
                const data = response.data;
                data.alumno_o_curso = data.alumno_o_curso?.toUpperCase();
                data.escuela = data.escuela?.toUpperCase();
                data.link = extractVideoId(data.link); // Extraer el ID del video si es una URL completa
                setVideoInfo(data);
            } catch (error) {
                console.error('Error fetching video info:', error);
                setError('Error fetching video info');
            } finally {
                setLoading(false);
            }
        };

        fetchVideoInfo();
    }, [videoId]);

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                const response = await axios.get(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`);
                setUserRole(response.data.role);
            } catch (error) {
                console.error('Error fetching user role:', error);
                setError('Error fetching user role');
            }
        };

        if (auth.user) {
            fetchUserRole();
        }
    }, [auth.user, auth.id]);

    useEffect(() => {
        const fetchAverageRating = async () => {
            try {
                const response = await axios.get(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/average-rating/${videoId}`);
                setAverageRating(response.data.averageRating);
            } catch (error) {
                console.error('Error fetching average rating:', error);
                setError('Error fetching average rating');
            }
        };

        fetchAverageRating();
    }, [videoId]);

    useEffect(() => {
        const fetchUserRating = async () => {
            try {
                const response = await axios.get(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/votes/${auth.user}/${videoId}`);
                const userVote = response.data[0]?.rating;
                setUserRating(userVote);
            } catch (error) {
                console.error('Error fetching user rating:', error);
                setError('Error fetching user rating');
            }
        };

        if (auth.user && (userRole === 'admin' || userRole === 'adminauto' || userRole === 'jurado')) {
            fetchUserRating();
        }
    }, [auth.user, userRole, videoId]);

    useEffect(() => {
        if (userRole === 'admin' || userRole === 'adminauto' || userRole === 'jurado') {
            setCanVote(true);
        } else {
            setCanVote(false);
        }
    }, [userRole]);

    const handleRatingChange = (event) => {
        const ratingValue = event.value;
        setUserRating(ratingValue);

        axios.post(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/vote`, {
            idVideo: videoId,
            usuario: auth.user,
            rating: ratingValue
        })
        .then(response => {
            console.log('Vote submitted successfully:', response.data);
        })
        .catch(error => {
            console.error('Error submitting vote:', error);
            setError('Error submitting vote');
        });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="p-d-flex p-jc-center">
            <Card className="p-mt-2" style={{ width: '70%' }}>
                {videoInfo ? (
                    <>
                        <h2>Video de {videoInfo.alumno_o_curso}</h2>
                        <div style={{ marginBottom: '10px' }}>
                            <span>Escuela: {videoInfo.escuela}</span>
                        </div>
                        <div className="p-d-flex p-jc-center p-mt-2">
                            {averageRating !== null && (
                                <div>
                                    <span>Promedio de votaciones: </span>
                                    <Rating value={averageRating} readonly cancel={false} />
                                </div>
                            )}
                        </div>
                        <div className="p-d-flex p-jc-center">
                            <iframe
                                width="560"
                                height="315"
                                src={`https://www.youtube.com/embed/${videoInfo.link}`} // Asegúrate de que videoInfo.link es solo el ID del video
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                        {canVote && (
                            <div className="p-d-flex p-jc-center p-mt-2">
                                <span>Mi votación: </span>
                                <Rating value={userRating} onChange={handleRatingChange} cancel={true} />
                            </div>
                        )}
                    </>
                ) : (
                    <p>{error || 'Error fetching video info'}</p>
                )}
            </Card>
        </div>
    );
};

export default YoutubeViewer;
