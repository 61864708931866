import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderContainer = styled.div`
  width: 95%;
  max-width: 1200px;
  margin: auto;
`;

const StyledSlider = styled(Slider)`
  .slick-slide img {
    width: 100%;
    height: auto;
  }

  .slick-dots {
    bottom: 10px;
  }

  .slick-dots li button:before {
    color: #d7412a; /* Cambia esto al color que prefieras */
  }

  .slick-dots li.slick-active button:before {
    color: #000; /* Cambia esto al color que prefieras */
  }

  .slick-prev,
  .slick-next {
    color: #d7412a; /* Cambia esto al color que prefieras */
  }

  .slick-prev:before,
  .slick-next:before {
    color: #d7412a; /* Cambia esto al color que prefieras */
  }
`;

const ImageSlider = () => {
  const [slides, setSlides] = useState([]);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const url = isMobile
          ? 'https://us-central1-inprojuy-f1173.cloudfunctions.net/app/ultimos-sliders?mobile=true'
          : 'https://us-central1-inprojuy-f1173.cloudfunctions.net/app/ultimos-sliders';
        
        const response = await axios.get(url);
        setSlides(response.data);
      } catch (error) {
        console.error('Error fetching slides:', error);
      }
    };

    fetchSlides();
  }, [isMobile]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <SliderContainer>
      <StyledSlider {...settings}>
        {slides.map(slide => (
          <div key={slide.id}>
            {slide.link ? (
              <a href={slide.link} target="_blank" rel="noopener noreferrer">
                <img src={slide.imagen} alt={slide.Slider} />
              </a>
            ) : (
              <img src={slide.imagen} alt={slide.Slider} />
            )}
          </div>
        ))}
      </StyledSlider>
    </SliderContainer>
  );
};

export default ImageSlider;
