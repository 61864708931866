import React from 'react'

const GaleriaFotos = () => {
    return (
        <div>

            <center><div className='botonbordeazul mx-auto my-3'>
                <h2 className='text-white px-4 py-3 h1'>Ganadores del último SORTEO UNA MAS!</h2>
            </div></center>
            <div className='container-fluid fondotransparente '>



                <div className=''>

                    <div className="card foto ms-auto container-fluid cardaut">
                        <div className='p-4'>
                            {/* <img src=".\img\ganador1ersorteo1.jpg" className="card-img" alt="..." /> */}
                            <img src=".\img\ganadoresultimosorteo.jpg" className="card-img" alt="..." />

                        </div>
                        <div className="card-body">
                            <h3 className="card-title"> </h3>
                            <h5 className="card-title"> </h5>
                            <p className="card-text"></p>
                        </div>
                    </div>
                    <br />


                </div>
            </div>
        </div>
    )
}

export default GaleriaFotos