import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Form, Button, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { subirFotoAuto } from "../../firebase/firebase.config";
import { useAuth } from "../../context/AuthContext";
import Switch from '@mui/material/Switch';

const ModificarAutoexcluido = () => {
  const auth = useAuth();
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        console.log('id: ', auth.id)
        const response = await axios.get(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`);
        setUserRole(response.data.role);
      } catch (error) {
        console.error('Error al obtener el rol del usuario:', error);
      }
    };
    fetchUserRole();
  }, [auth.id]);

  const { dni } = useParams();
  const [autoexcluidoData, setAutoexcluidoData] = useState(null);
  const [modificacionData, setModificacionData] = useState({
    nombre: '',
    dni: '',
    domicilio: '',
    nacionalidad: '',
    telefono: '',
    mail: '',
    declaracion1: false,
    declaracion11: false,
    declaracion2: false,
    declaracion3: false,
    declaracion4: false,
    declaracion5: false,
    cambios: [],
    fotos: [],
    activo: false,
    telefonoApoyo: '',
    vencimiento: '',
    nombrePersonaApoyo: '',
    observaciones: [],
    fechaAlta: '',
    // Agregar otros campos según la nueva estructura
  });

  const [foto, setFoto] = useState(null);
  const [changesLog, setChangesLog] = useState([]);

  const handleModificar = async (e) => {
    e.preventDefault();
    const observaciones = modificacionData.observaciones || [];

    const validSize = 250 * 1024; // 250KB
  
    if (foto) {
      const fileType = await getFileType(foto);
  
      if (fileType !== 'image/jpeg' || foto.size > validSize) {
        alert('La foto no es válida. Debe ser de formato JPG y tener un tamaño menor a 250KB.');
        return;
      }
    }
  
    try {
      const fotoURL = foto ? await subirFotoAuto(foto) : null;
  
      const cambios = [];
  
      Object.keys(modificacionData).forEach((campo) => {
        if (campo !== 'cambios' && campo !== 'vencimiento' && campo !== 'observaciones' && campo !== 'fechaAlta' && autoexcluidoData[campo] !== modificacionData[campo]) {
          if (userRole !== "admin" || userRole !== "adminauto") {
            return alert('Acceso no autorizado. Usted no tiene permiso para acceder a esta página.');
          }
  
          const cambio = {
            tipo: `Modificación ${campo}`,
            marcaDeTiempo: new Date().toISOString(),
            valorAnterior: autoexcluidoData[campo],
            valorActual: modificacionData[campo],
            usuario: auth.user,
          };
  
          cambios.push(cambio);
        }
      });
  
      if (fotoURL && fotoURL !== autoexcluidoData.fotos[0]) {
        cambios.push({
          tipo: 'Modificación Foto',
          marcaDeTiempo: new Date().toISOString(),
          valorAnterior: autoexcluidoData.fotos.filter((foto) => foto !== null).pop() || null,
          valorActual: fotoURL,
          usuario: auth.user,
        });
      }
  
      if (modificacionData.vencimiento !== autoexcluidoData.vencimiento) {
        cambios.push({
          tipo: 'Modificación Fecha de Vencimiento',
          marcaDeTiempo: new Date().toISOString(),
          valorAnterior: autoexcluidoData.vencimiento,
          valorActual: modificacionData.vencimiento,
          usuario: auth.user,
        });
      }
    
      if (modificacionData.nombre !== autoexcluidoData.nombre) {
        cambios.push({
          tipo: 'Modificación Nombre',
          marcaDeTiempo: new Date().toISOString(),
          valorAnterior: autoexcluidoData.nombre,
          valorActual: modificacionData.nombre,
          usuario: auth.user,
        });
      }

      if (modificacionData.nombrePersonaApoyo !== autoexcluidoData.nombrePersonaApoyo) {
        cambios.push({
          tipo: 'Modificación Nombre de Apoyo',
          marcaDeTiempo: new Date().toISOString(),
          valorAnterior: autoexcluidoData.nombrePersonaApoyo,
          valorActual: modificacionData.nombrePersonaApoyo,
          usuario: auth.user,
        });
      }
  
      if (modificacionData.fechaAlta !== autoexcluidoData.fechaAlta) {
        cambios.push({
          tipo: 'Modificación Fecha de Alta',
          marcaDeTiempo: new Date().toISOString(),
          valorAnterior: autoexcluidoData.fechaAlta,
          valorActual: modificacionData.fechaAlta,
          usuario: auth.user,
        });
      }
  
      const nuevasObservaciones = modificacionData.observaciones
        .filter(obs => obs.observacion.trim() !== '')
        .map(obs => ({
          fecha: obs.fecha || new Date().toISOString(),
          observacion: obs.observacion,
        }));
  
      if (nuevasObservaciones.length > 0) {
        cambios.push({
          tipo: 'Modificación Observaciones',
          marcaDeTiempo: new Date().toISOString(),
          valorAnterior: 'N/A',
          valorActual: 'N/A',
          usuario: auth.user,
        });
      }
  
      const observaciones = [
        ...autoexcluidoData.observaciones,
        ...nuevasObservaciones,
      ];
      
      if (cambios.length > 0) {
        const nuevasFotos = [...autoexcluidoData.fotos];
        const ultimaFotoNoNula = nuevasFotos.filter((foto) => foto !== null)[nuevasFotos.length - 1];
  
        if (fotoURL && fotoURL !== ultimaFotoNoNula) {
          nuevasFotos.push(fotoURL);
        }
  
        await axios.post(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/modiautoexcluidos/${autoexcluidoData.id}`, {
          id: autoexcluidoData.id,
          nombre: modificacionData.nombre,
          declaracion2: modificacionData.declaracion2,
          alta: modificacionData.alta,
          vencimiento: modificacionData.vencimiento,
          declaracion1: modificacionData.declaracion1,
          mail: modificacionData.mail,
          nombrePersonaApoyo: modificacionData.nombrePersonaApoyo,
          observaciones:  observaciones,
          cambios: [...modificacionData.cambios, ...cambios],
          fotos: nuevasFotos,
          activo: modificacionData.activo,
          // Agregar otros campos según la nueva estructura
        });
  
        const cambiosConUsuario = cambios.map((cambio) => ({ ...cambio, usuario: autoexcluidoData.nombre }));
        setChangesLog((prevLog) => [...prevLog, ...cambiosConUsuario]);
  
        alert('Datos modificados correctamente');
      } else {
        alert('No se realizaron cambios');
      }
    } catch (error) {
      console.error('Error al modificar autoexcluido', error);
      alert('Error al modificar autoexcluido. Inténtalo de nuevo.');
    }
  };
  

  const getFileType = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        const arr = new Uint8Array(reader.result).subarray(0, 4);
        let header = '';
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        resolve(getMimeType(header));
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const getMimeType = (header) => {
    switch (header) {
      case '89504e47':
        return 'image/png';
      case '47494638':
        return 'image/gif';
      case 'ffd8ffe0':
      case 'ffd8ffe1':
      case 'ffd8ffe2':
        return 'image/jpeg';
      case '504b0304':
        return 'application/zip';
      default:
        return 'unknown';
    }
  };

  const handleFotoChange = (e) => {
    const file = e.target.files[0];
    setFoto(file);
  };

  const handleVencimientoChange = (e) => {
    const selectedDate = new Date(e.target.value);
    if (!isNaN(selectedDate.getTime())) {
      const formattedDate = selectedDate.toISOString();
      const cambios = [];

      // Verificar si la fecha ha cambiado antes de actualizar el estado
      if (formattedDate !== modificacionData.vencimiento) {
        const nuevasObservaciones = modificacionData.observaciones
          .filter(obs => obs.observacion.trim() !== '')
          .map(obs => ({
            fecha: obs.fecha || new Date().toISOString(),
            observacion: obs.observacion,
          }));
  
        // Actualizar el estado con la nueva fecha de vencimiento y las observaciones
        setModificacionData((prevData) => ({
          ...prevData,
          vencimiento: formattedDate,
          observaciones: [...prevData.observaciones, ...nuevasObservaciones],
        }));
  
        // Registrar el cambio en el log
        cambios.push({
          tipo: 'Modificación Fecha de Vencimiento',
          marcaDeTiempo: new Date().toISOString(),
          valorAnterior: autoexcluidoData.vencimiento,
          valorActual: formattedDate,
          usuario: auth.user,
        });
      }
    } else {
      console.error('Fecha inválida');
    }
  };

  useEffect(() => {
    if (dni) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/autoexcluidos/${dni}`);
          setAutoexcluidoData(response.data);
          setModificacionData({
            nombre: response.data.nombre || '',
            dni: response.data.dni || '',
            domicilio: response.data.domicilio || '',
            nacionalidad: response.data.nacionalidad || '',
            telefono: response.data.telefono || '',
            mail: response.data.mail || '',
            declaracion1: response.data.declaracion1 || false,
            declaracion11: response.data.declaracion11 || false,
            declaracion2: response.data.declaracion2 || false,
            declaracion3: response.data.declaracion3 || false,
            declaracion4: response.data.declaracion4 || false,
            declaracion5: response.data.declaracion5 || false,
            cambios: response.data.cambios || [],
            fotos: response.data.fotos || [],
            activo: response.data.activo || false,
            telefonoApoyo: response.data.telefonoApoyo || '',
            alta: response.data.alta || '',
            vencimiento: response.data.vencimiento || '',
            nombrePersonaApoyo: response.data.nombrePersonaApoyo || '',
            observaciones: response.data.observaciones || [],
            fechaAlta: response.data.fechaAlta || '',
            // Agregar otros campos según la nueva estructura
          });
        } catch (error) {
          console.error('Error al obtener datos del autoexcluido', error);
        }
      };

      fetchData();
    }
  }, [dni]);


  return (
    <div className="container-fluid py-5 fondotransparente5">
      <Container style={{ height: '100%', width: '100%' }}>
        <div style={{ height: '100%', width: '100%' }}>
          <center>
            <h2 className="botonbordeazul text-white px-5 py-3 mb-4">Modificar Autoexcluido</h2>
          </center>
          <br />
          {autoexcluidoData && (
            <>
              <hr />
              <div>
                <h4>Modificar Información</h4>
                <Form onSubmit={handleModificar}>
                  <Form.Group className="mb-3" controlId="formNombre">
                    <Form.Label>Nombre:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el nombre"
                      value={modificacionData.nombre}
                      onChange={(e) => setModificacionData({ ...modificacionData, nombre: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formDni">
                    <Form.Label>DNI:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el DNI"
                      value={modificacionData.dni}
                      onChange={(e) => setModificacionData({ ...modificacionData, dni: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formDomicilio">
                    <Form.Label>Domicilio:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el domicilio"
                      value={modificacionData.domicilio}
                      onChange={(e) => setModificacionData({ ...modificacionData, domicilio: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formNacionalidad">
                    <Form.Label>Nacionalidad:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese la nacionalidad"
                      value={modificacionData.nacionalidad}
                      onChange={(e) => setModificacionData({ ...modificacionData, nacionalidad: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formTelefono">
                    <Form.Label>Teléfono:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el teléfono"
                      value={modificacionData.telefono}
                      onChange={(e) => setModificacionData({ ...modificacionData, telefono: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formTelefonoapoyo">
                    <Form.Label>Teléfono de Apoyo:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el teléfono"
                      value={modificacionData.telefonoApoyo}
                      onChange={(e) => setModificacionData({ ...modificacionData, telefonoApoyo: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formMail">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Ingrese el email"
                      value={modificacionData.mail}
                      onChange={(e) => setModificacionData({ ...modificacionData, mail: e.target.value })}
                    />
                  </Form.Group>
               
                  <Form.Group className="mb-3" controlId="formFechaAlta">
  <Form.Label>Fecha de Alta:</Form.Label>
  <Form.Control
    type="date"
    value={modificacionData.alta}
    onChange={(e) => setModificacionData({ ...modificacionData, alta: e.target.value })}
  />
</Form.Group>

                  <Form.Group className="mb-3" controlId="formVencimiento">
                    <Form.Label>Fecha de Vencimiento:</Form.Label>
                    <Form.Control
                      type="date"
                      value={modificacionData.vencimiento}
                      onChange={handleVencimientoChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formNombrePersonaApoyo">
                    <Form.Label>Nombre de Persona de Apoyo:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ingrese el nombre"
                      value={modificacionData.nombrePersonaApoyo}
                      onChange={(e) => setModificacionData({ ...modificacionData, nombrePersonaApoyo: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group controlId="formObservaciones">
                    <Form.Label>Observaciones:</Form.Label>
                    {modificacionData.observaciones.map((obs, index) => (
                      <div key={index} className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder={`Observación #${index + 1}`}
                          value={obs.observacion}
                          onChange={(e) => {
                            const nuevasObservaciones = [...modificacionData.observaciones];
                            nuevasObservaciones[index].observacion = e.target.value;
                            setModificacionData({ ...modificacionData, observaciones: nuevasObservaciones });
                          }}
                        />
                      </div>
                    ))}
                    <Button
                      variant="secondary"
                      onClick={() => setModificacionData({
                        ...modificacionData,
                        observaciones: [...modificacionData.observaciones, { fecha: null, observacion: '' }]
                      })}
                    >
                      Agregar Observación
                    </Button>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formFoto">
                    <Form.Label>Foto:</Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      onChange={handleFotoChange}
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Modificar
                  </Button>
                </Form>
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ModificarAutoexcluido;
