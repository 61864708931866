import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Table, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Switch from '@mui/material/Switch';

function PersonaDetails({ dni }) {
  const [personaData, setPersonaData] = useState(null);
  const [loading, setLoading] = useState(false);

  const formatFechaHora = (marcaDeTiempo) => {
    const fecha = new Date(marcaDeTiempo);
    const opciones = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "UTC",
    };
    return new Intl.DateTimeFormat("es-AR", opciones).format(fecha);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/autoexcluidos/${dni}`
        );
        setPersonaData(response.data);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          alert("Persona no encontrada");
          window.location.reload();
        } else {
          console.error("Error fetching persona data", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dni]);

  return (
    <div className="container-fluid py-5 fondotransparente5">
      <Container style={{ height: "100%", width: "100%" }}>
        <div style={{ height: "100%", width: "100%" }}>
          {personaData && (
            <>
              <hr />
              <div style={{ textAlign: "center" }}>
                <Image
                  src={
                    personaData.fotos.filter((foto) => foto !== null).pop() || ""
                  }
                  alt={`Foto de ${personaData.nombre}`}
                  fluid
                  style={{
                    width: "300px",
                    height: "300px",
                    objectFit: "cover",
                  }}
                />
              </div>
              <ul style={{ fontSize: "1.2em" }}>
                <h4>Nombre: {personaData.nombre}</h4>
                <h5>DNI: {personaData.dni}</h5>
                <h5>Email: {personaData.mail}</h5>
                <h5>Domicilio: {personaData.domicilio}</h5>
                <h5>Nacionalidad: {personaData.nacionalidad}</h5>
                <h5>Teléfono: {personaData.telefono}</h5>
                <h5>Teléfono de Apoyo: {personaData.telefonoApoyo}</h5>
                <h5>Ocupación: {personaData.ocupacion}</h5>
                <h5>Localidad: {personaData.localidad}</h5>
                <h5>Fecha de Nacimiento: {personaData.fechaNacimiento}</h5>
                <h5>Activo: <Switch checked={personaData.activo} /></h5>
                <h5>Fecha de Alta: {(personaData.alta.split('T')[0])}</h5>
                <h5>Fecha de Vencimiento: {personaData.vencimiento.split('T')[0]}</h5>
                <h5>Lugar de Entrega: {personaData.lugarEntrega}</h5>
              </ul>
              {personaData.pdfDocument && personaData.pdfDocument.length > 0 && (
                <>
                  <h3>Documentos Adjuntos:</h3>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Documento</th>
                        <th>Pdf</th>
                      </tr>
                    </thead>
                    <tbody>
                      {personaData.pdfDocument.map((documento, index) => (
                        <tr key={index}>
                          <td>{documento.nombredoc}</td>
                          <td>
                            <a href={documento.url} target="_blank" rel="noopener noreferrer">
                              Ver PDF
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}

              {personaData.observaciones && personaData.observaciones.length > 0 && (
                <>
                  <h3>Observaciones:</h3>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Observación</th>
                      </tr>
                    </thead>
                    <tbody>
                      {personaData.observaciones.map((observacion, index) => (
                        <tr key={index}>
                          <td>{(observacion.fecha.split('T')[0])}</td>
                          <td>{observacion.observacion}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}

              {personaData.cambios && personaData.cambios.length > 0 && (
                <>
                  <h3>Historial de Cambios:</h3>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Tipo de Cambio</th>
                        <th>Fecha y Hora</th>
                        <th>Usuario</th>
                        <th>Valor Anterior</th>
                        <th>Valor Actual</th>
                      </tr>
                    </thead>
                    <tbody>
                      {personaData.cambios.map((cambio, index) => (
                        <tr key={index}>
                          <td>{cambio.tipo}</td>
                          <td>{formatFechaHora(cambio.marcaDeTiempo)}</td>
                          <td>{cambio.usuario}</td>
                          <td>{cambio.valorAnterior}</td>
                          <td>{cambio.valorActual}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}

             
            </>
          )}
        </div>
      </Container>
    </div>
  );
}

export default PersonaDetails;
