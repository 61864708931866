import React, { useState, useEffect } from 'react';
import "./salas.css";

const Salas = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="salas">
      <div className="image-container">
        <img
          className='imagensalas'
          src={isMobile ? './img/salasm.png' : './img/salas.png'}
          alt='Salas'
        />
        <button className={`boton-videodrome ${isMobile ? 'mobile' : ''}`}>
          <a href="https://casinosmacgroup.bet.ar" target="_blank" rel="noopener noreferrer">
            <img className='salasbtnimg' src='./img/videodrome.png' alt='Videodrome' />
          </a>
        </button>
        <button className={`boton-superstar ${isMobile ? 'mobile' : ''}`}>
          <a href="https://superstar.com.ar" target="_blank" rel="noopener noreferrer">
            <img className='salasbtnimg' src='./img/superstar.png' alt='Superstar' />
          </a>
        </button>
      </div>
    </div>
  );
};

export default Salas;
