import React from 'react'

const NormativaComplementaria = () => {
    return (
        <div className='container fondotransparente p-5 my-5 '>
            <center><h1 className=" p-5 botonbordeazul text-white px-5 mx-5 py-3">Normativa Complementaria</h1></center>
            <br />
            <br />
            <p>
                El marco normativo que regula la prevención del lavado de activos y la financiación del terrorismo incluye varias
                leyes y resoluciones que deben ser cumplidas por el INPROJUY. A continuación, se destacan las principales normativas:
            </p>
            <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                            Ley 25.246
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                        <div className="accordion-body">
                            <p>
                                Modificación sobre encubrimiento y lavado de activos de origen delictivo. Más
                                información disponible en:
                                <a href="https://servicios.infoleg.gob.ar/infolegInternet/anexos/60000-64999/62977/texact.htm" target="_blank">
                                    servicios.infoleg.gob.ar
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                            Resolución 194/2023
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                        <div className="accordion-body">
                            <p>
                                Emitida por la Unidad de Información Financiera (UIF) para actualizar las
                                regulaciones sobre lavado de activos. Detalles en:
                                <a href="https://www.argentina.gob.ar/normativa/nacional/resoluci%C3%B3n-194-2023-390692/" target="_blank">
                                    argentina.gob.ar
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                            Resolución UIF Nº 35/2023
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                        <div className="accordion-body">
                            <p>
                                Referente a Personas Expuestas Políticamente (PEPs). Información
                                completa en:
                                <a href="https://www.boletinoficial.gob.ar/detalleAviso/primera/281989/20230302" target="_blank">
                                    boletinoficial.gob.ar
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                        <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                            Resolución Nº 157-P/2022
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                        <div className="accordion-body">
                            <p>
                                Manual de Política y Procedimiento del INPROJUY sobre el Lavado de
                                Activos, emitida el 22 de julio de 2022.
                            </p>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                            <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                Resolución N° -INPROJUY-2024
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                            <div className="accordion-body">
                                <p>Pauta interpretativa sobre la actividad habitual del artículo 20º
                                    de la Ley Nacional 25.246, en espera de publicación oficial.</p>

                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                            <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                               Manual  de prevención de lavado de activos y financiamiento del terrorismo
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
                            <div className="accordion-body">
                                <p><a href=".\download\MANUAL-PREVENCION-LAVADO-ACTIVOS.pdf" download>Descargar Aqui</a></p>

                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingSeven">
                            <button className="h3 accordion-button text-light bg-primary collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                            Resolución N°
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseSeven" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven">
                            <div className="accordion-body">
                                <p>PAUTA INTERPRETATIVA DE ACTIVIDAD HABITUAL DEL ARTICULO 20º DE LA LEY NACIONAL 25.246 DE UNIDAD DE INFORMACIÓN FINANCIERA</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NormativaComplementaria