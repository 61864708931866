import React from 'react';
import { Modal } from 'react-bootstrap';
import PersonaDetails from './PersonaDetails';

function ProfileModal({ show, handleClose, dni }) {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Perfil Completo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PersonaDetails dni={dni} />
      </Modal.Body>
    </Modal>
  );
}

export default ProfileModal;
