import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Logout() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [sorteos, setSorteos] = useState([]);
  const [selectedSorteo, setSelectedSorteo] = useState("");
  const [userRole, setUserRole] = useState(null);


  useEffect(() => {
    fetchSorteos();

    const fetchUserRole = async () => {
      try {
        const response = await axios.get(
          `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`
        );
        setUserRole(response.data.role);
      } catch (error) {
        console.error("Error al obtener el rol del usuario:", error);
      }
    };

    fetchUserRole();
  }, [auth.id]);

  const fetchSorteos = async () => {
    try {
      const response = await axios.get("https://us-central1-inprojuy-f1173.cloudfunctions.net/app/sorteos");
      setSorteos(response.data);
    } catch (error) {
      console.error("Error al obtener los sorteos:", error);
    }
  };

  const handleSorteoChange = (event) => {
    setSelectedSorteo(event.target.value);
  };

  const navigateToConsultarCupones = () => {
    if (selectedSorteo) {
      navigate(`/cupones/${auth.id}/${selectedSorteo}`);
    }
  };



  return (
    <div className="">
      <center>
        <h1 className="botonbordeazul text-white px-5 mx-5 py-3 h1">
          Perfil de Usuario
        </h1>
      </center>

      <div className="animate-pulse">
        <h2 className="my-3">E-mail: {auth.user}</h2>
        <h2 className="my-4">Usuario: {auth.userName}</h2>
      </div>
      <br />

      <div>
        <Link to="/profile">
          <button >
           Datos del usuario
          </button>
        </Link>
      </div>
      <p></p>

      <form>
        <div style={{ border: '1px solid #000', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', padding: '10px', borderRadius: '5px' }}>
          <h3>Consulta de Cupones no Ganadores cargados</h3>

          <select value={selectedSorteo} onChange={handleSorteoChange}>
            <option value="">Seleccione sorteo</option>
            {sorteos.map((sorteo) => (
              <option key={sorteo.n_sorte} value={sorteo.n_sorte}>
                {sorteo.n_sorte} - {sorteo.mes}
              </option>
            ))}
          </select>
          <div style={{ width: '10px' }} />
          <button
            disabled={!selectedSorteo}
            onClick={navigateToConsultarCupones}
          >
            <>Consultar Cupones</>
          </button>
        </div>
      </form>
      <br />

      <div></div>
    
    </div>
  );
}

export default Logout;
