import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

const Cards = ({ showAllCards }) => {
  const [tabn, setTabn] = useState([]);
  const [maxCards, setMaxCards] = useState(showAllCards ? tabn.length : 4);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Realizar la solicitud a la API y actualizar el estado
    fetch('https://us-central1-inprojuy-f1173.cloudfunctions.net/app/noticias')
      .then(response => response.json())
      .then(data => {
        setTabn(data);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const rotationInterval = setInterval(() => {
      if (tabn.length > 0) {
        // Cambiar a la siguiente tarjeta
        setCurrentCardIndex((prevIndex) => (prevIndex + 1) % maxCards);
      }
    }, 7000); // 7 segundos

    // Limpieza del intervalo cuando el componente se desmonta
    return () => {
      clearInterval(rotationInterval);
    };
  }, [maxCards, tabn]);

  return (
    <div className="container bg-gray" style={{ height: "88vh", position: "relative" }}>
      <div className="row" style={{ height: "100%" }}>
        <div className={`col-md-12 ${isMobile ? 'mobile-image-container' : ''}`} style={{ height: "90vh" }}>
          {!isMobile && tabn.length > 0 && (
            <div
              key={tabn[currentCardIndex]?.id} // Usar operador de encadenamiento opcional
              style={{
                position: "relative",
                width: "94vw",
                height: "100vh",
                display: isMobile ? "none" : "block",
              }}
            >
              <img
                src={tabn[currentCardIndex]?.imagenUrl} // Usar operador de encadenamiento opcional
                alt={tabn[currentCardIndex]?.titulo} // Usar operador de encadenamiento opcional
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <center>
                <h1
                  className={`col-md-5 p-4 ${isMobile ? 'mobile-title' : 'desktop-title'}`}
                  style={{
                    position: "absolute",
                    bottom: "10%",
                    left: "10%",
                    color: "white",
                    textShadow: "4px 4px 6px rgba(0, 0, 0, 0.8)",
                    fontWeight: "bold",
                  }}
                >
                  {tabn[currentCardIndex]?.titulo} {/* Usar operador de encadenamiento opcional */}
                </h1>
              </center>
            </div>
          )}
          {isMobile && tabn.length > 0 && (
            <div
              key={tabn[currentCardIndex]?.id}
              style={{
                position: "relative",
                width: "100%",
                height: "50vh",
                display: isMobile ? "block" : "none",
              }}
            >
              <img
                src={tabn[currentCardIndex]?.imagenUrl}
                alt={tabn[currentCardIndex]?.titulo}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <center>
                <h1
                  className={`p-2 mobile-title`}
                  style={{
                    position: "absolute",
                    bottom: "10%",
                    left: "10%",
                    color: "white",
                    textShadow: "4px 4px 6px rgba(0, 0, 0, 0.8)",
                    fontWeight: "bold",
                  }}
                >
                  {tabn[currentCardIndex]?.titulo}
                </h1>
              </center>
            </div>
          )}
        </div>
        <div
          className={isMobile ? "col-md-12 mobile-cards-container" : "col-md-6"}
          style={{
            height: "100%",
            position: isMobile ? "relative" : "absolute",
            top: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {tabn.slice(0, maxCards).map((card, index) => (
            card && card.id && (
              <div
                className={`p-3 ${isMobile ? 'mobile-card' : ''}`}
                key={card.id}
                style={{
                  backgroundColor: "rgba(59, 160, 168, 0.6)",
                  borderRadius: "5px",
                  margin: isMobile ? "10px 0" : "20px",
                  height: isMobile ? "auto" : "20%",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: "center",
                  justifyContent: isMobile ? "center" : "flex-end",
                }}
              >
                <Link to={`/noti/${card.id}`} style={{ textDecoration: "none", color: "inherit" }}>
                  <h2
                    className={`${isMobile ? 'mobile-subtitle' : 'desktop-subtitle'}`}
                    style={{
                      cursor: "pointer",
                      fontWeight: "normal",
                      transition: "0.3s",
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
                      marginBottom: isMobile ? "10px" : "0",
                    }}
                    onMouseOver={(e) => {
                      e.target.style.fontWeight = "bold";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.fontWeight = "normal";
                    }}
                  >
                    {card.titulo}
                  </h2>
                </Link>
                <img
                  width="232"
                  height="140"
                  src={card?.imagenUrl}
                  alt={card?.titulo}
                  style={{
                    objectFit: "cover",
                    width: isMobile ? "100%" : "60%",
                    height: isMobile ? "auto" : "100%",
                  }}
                />
              </div>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cards;
