import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function UserLookup() {
  const navigate = useNavigate();

  const auth = useAuth(); // Nuevo contexto de autenticación
  const [numero, setNumero] = useState("");
  const [sorteo, setSorteo] = useState("");
  const [sorteos, setSorteos] = useState([]);
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null); // Nuevo estado para el rol del usuario
  const [turnoSeleccionado, setTurnoSeleccionado] = useState(""); // Nuevo estado para el turno seleccionado

  useEffect(() => {
    fetch("https://us-central1-inprojuy-f1173.cloudfunctions.net/app/sorteos")
      .then((response) => response.json())
      .then((data) => setSorteos(data))
      .catch((error) => console.error(error));

    // Cargar el rol del usuario al cargar el componente
    const fetchUserRole = async () => {
      try {
        const response = await axios.get(
          `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`
        );
        setUserRole(response.data.role);
      } catch (error) {
        console.error("Error al obtener el rol del usuario: " + auth.id+ '*', error);
      }
    };
    fetchUserRole();
  }, [auth.id]);

  const handleSorteoChange = (event) => {
    setSorteo(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Verificar el rol antes de hacer la búsqueda
    if (userRole !== "admin") {
      alert("Acceso no autorizado");
      return;
    }
 
    try {
      const response = await axios.get(
        `https://us-central1-inprojuy-f1173.cloudfunctions.net/app/cuponesusuario/${numero}/${sorteo}/${turnoSeleccionado}`
      );
      setUserData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        alert(error.response.data.message);
        window.location.reload();
      } else {
        console.error("Error fetching user data", error);
      }
    }
  };

  return (
    <div>
      <Container style={{ height: 650, width: '100%' }}>
        <div style={{ height: 500, width: '100%' }}>
          <center>
            <h2 className="botonbordeazul text-white px-5 py-3 mb-4">
              Buscar Ganadores
            </h2>
          </center>
          <br />
          <form onSubmit={handleSubmit}>
            <div className="cuerpo">
              <label>
                Seleccione sorteo:
                <select value={sorteo} onChange={handleSorteoChange}>
                  <option value="">Seleccione sorteo</option>
                  {sorteos.map((sort) => (
                    <option key={sort.n_sorte} value={sort.n_sorte}>
                      {sort.n_sorte} - {sort.mes}
                    </option>
                  ))}
                </select>
              </label>{" "}
              <label>
                Seleccione el turno:
                <select value={turnoSeleccionado} onChange={(e) => setTurnoSeleccionado(e.target.value)}>
                  <option value="">Seleccione turno</option>
                  <option value="Previa">Previa</option>
                  <option value="Primera">Primera</option>
                </select>
              </label>{" "}
              <label>
                Ingrese el número de participación:  
                <input
                  type="number"
                  value={numero}
                  onChange={(e) => setNumero(e.target.value)}
                />
              </label>{" "}
              <button type="submit" className="btn btn-primary ">
                Buscar usuario
              </button>{" "}
              <button className='btn btn-outline-danger' onClick={() => navigate('/Dashboard')}>Volver</button>
            </div>
          </form>

          {userData && (
            <>
              <ul>
                <h4>
                  Número de Cupón: {userData.cMaqre} - {userData.cNumber} -{" "}
                  {userData.nCupon}
                </h4>
                <h5>Nombre: {userData.nombre}</h5>
                <h5>Apellido: {userData.apellido}</h5>
                <h5>DNI: {userData.dni}</h5>
                <h5>Email: {userData.email}</h5>
                <h5>Barrio: {userData.barrio}</h5>
                <h5>Fecha de nacimiento: {userData.fechaNacimiento}</h5>
                <h5>Dirección: {userData.direccion}</h5>
                <h5>Localidad: {userData.localidad}</h5>
                <h5>Teléfono: {userData.telefono}</h5>
                <h5>Agencia Vendedora: {userData.c_agent}</h5>
              </ul>
            </>
          )}
        </div>
      </Container>
    </div>
  );
}

export default UserLookup;
