import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import "../components/login.css"; // Importa tu archivo de estilos CSS aquí

function Form({ nameForm }) {
  const [viewPassword, setViewPassword] = useState("password");
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const auth = useAuth();
  const error = auth.error;

  const validatePassword = () => {
    if (password.length < 6) {
      setPasswordError("La contraseña debe tener al menos 6 caracteres");
      return false;
    }
    // Agrega otras restricciones de validación de la contraseña aquí según tus necesidades
    return true;
  };

  const handlerAuth = (e) => {
    e.preventDefault();
    if (nameForm === "Ingresar") {
      try {
        auth.login(email, password);
      } catch (error) {
        console.error(error);
      }
    } else if (nameForm === "Registrarse") {
      try {
        if (password === secondPassword && validatePassword()) {
          console.log("por entrar" + user + email + password);
          auth.register(user, email, password);
        }
      } catch (error) {
        console.error(error + "hola");
      }
    }
  };

  const googleButton = async (e) => {
    try {
      await auth.googleAuth();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="row ">
      {/* Columna de la izquierda */}
      <div className="col-md-6 ">
        <div className="auth-content my-auto text-center ">
          {error && (
            <div className="text-center">
              <div className="text-danger p-2">
                {error && nameForm === "Registrarse" ? (
                  <a>Error al Registrarse</a>
                ) : (
                  <a>Error de Credenciales</a>
                )}
              </div>
            </div>
          )}
          <div>
            <center>
              <img className="logo" src="./img/inprojuylogo1.png" alt="INPROJUY" />
            </center>
            <h2 className="p-3">{nameForm}</h2>
          </div>
          <form
            className="mt-4 pt-2"
            action="#"
            method="POST"
            onSubmit={(e) => handlerAuth(e)}
          >
            {nameForm === "Registrarse" && (
              <div className=" form-floating-custom mb-4">
                <input
                  onChange={(e) => setUser(e.target.value)}
                  id="user"
                  name="user"
                  type="text"
                  autoComplete=""
                  required
                  className="border px-3 py-2 rounded placeholder-gray-500"
                />
                <label htmlFor="user">Usuario</label>
              </div>
            )}
            <div className="form-floating-custom mb-4">
              <input
                onChange={(e) => setEmail(e.target.value)}
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="border px-3 py-2 rounded placeholder-gray-500"
              />
              <label htmlFor="email-address">E-mail</label>
            </div>
            <div className=" form-floating-custom mb-4">
              <input
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                name="password"
                type={viewPassword}
                autoComplete="current-password"
                required
                className="border borde px-3 py-2 rounded placeholder-gray-500"
              />
              <label htmlFor="password">Contraseña</label>
            </div>
            {nameForm === "Registrarse" && (
              <div className=" form-floating-custom mb-4">
                <input
                  onChange={(e) => setSecondPassword(e.target.value)}
                  id="confirm-password"
                  name="confirm-password"
                  type={viewPassword}
                  autoComplete="current-password"
                  required
                  className="border px-3 py-2 rounded placeholder-gray-500"
                />
                <label htmlFor="confirm-password">Confirmar Contraseña</label>
              </div>
            )}
            <div className="flex items-center justify-between flex-col">
              <div className="flex items-center">
                <input
                  onChange={() =>
                    setViewPassword(
                      viewPassword === "password" ? "text" : "password"
                    )
                  }
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className=""
                />
                <label htmlFor="remember-me" className="p-2">
                  Mostrar contraseña
                </label>
              </div>
              <div className="text-sm">
                <a
                  href="#"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  <Link to="/reset">Olvidaste tu contraseña?</Link>
                </a>
              </div>
            </div>
            <div className="mb-3 text-center">
              <button
                type="submit"
                className={`group relative ${
                  password !== secondPassword ? "bg-gray-600" : "bg-indigo-600"
                } btn btn-primary text-white waves-effect waves-light`}
              >
                {nameForm}
              </button>
            </div>
            <div className="mt-3 text-center">
              <Link to="/reset" className="text-muted fw-medium">
                ¿Olvidaste tu contraseña?
              </Link>
            </div>
          </form>
          <div className="mt-4 pt-2 text-center">
            <div className="signin-other-title">
              <h5 className="font-size-14 mb-3 text-muted fw-medium">
                - Iniciar sesión con -
              </h5>
            </div>
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <button
                  className=" bg-primary text-white border-primary"
                  onClick={() => googleButton()}
                >
                  Ingresar con Google  <a> </a>
                  <FcGoogle />
                </button>
              </li>
            </ul>
          </div>
          <div className="mt-5 text-center">
            <p className="text-muted mb-0">
              ¿No tienes una cuenta?{" "}
              <Link to="/register" className="text-primary fw-semibold">
                Regístrate ahora
              </Link>{" "}
            </p>
          </div>
        </div>
      </div>

      {/* Columna de la derecha */}
      <div className="col-6">
        <div className="auth-bg pt-md-5 p-4 d-flex">
          {/* Contenido de la columna de la derecha... */}
          <div className="bg-overlay"></div>
          <ul className="bg-bubbles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div className="row justify-content-center align-items-end">
            <div className="col-xl-7">
              <div className="p-0 p-sm-4 px-xl-0">
                <div
                  id="reviewcarouselIndicators"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  {/* Resto del contenido de la columna de la derecha... */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
