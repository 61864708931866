import React from 'react'

const Reglamentos = () => {
    return (
        <div className='container fondotransparente p-5 my-5 '>
            <center><h1 className=" p-5 botonbordeazul text-white px-5 mx-5 py-3">Leyes y Reglamentos</h1></center>
            <br />

            <p>Encontrá todas las Leyes y Reglamentos publicados en InProJuy.</p>

            <br />
            <br />
            <p><strong>Leyes</strong></p>
            <br />


            <ul>
                {/* <li className='h5 sinvineta'><a href=".\download\ley-6234-Creacion-InProJuy.pdf" download >LEY 6234 CREACIÓN INPROJUY</a></li> */}



                <li className='h5 sinvineta'><a href=".\download\LEY-DE-CREACION-DEL-INSTITUTO-DE-JUEGOS-DE-AZAR-DE-JUJUY.pdf" download>LEY DE CREACIÓN DEL INSTITUTO DE JUEGOS DE AZAR DE JUJUY</a></li>
            </ul>

            <br />

            <hr className="wp-block-separator has-css-opacity" />

            <br />

            <p><strong>Reglamentos</strong></p>

            <br />

            <ul>
                <li className='h5 sinvineta'><a href=".\download\reglamento-tombola.pdf" download >REGLAMENTO DE TÓMBOLA</a></li>

                <br />
                <li className='h5 sinvineta'><a href=".\download\ReglamentoCuponesNoPremiados.pdf" download >REGLAMENTO DE UNA MAS!</a><a href=".\download\R208-p-2023.pdf" download >, Resolucion N° 208-P-2023</a></li>

                <br />
                <li className='h5 sinvineta'><a href=".\download\BetWarrior.pdf" download >REGLAMENTO BETWARRIOR</a></li>

                <br />

                <li className='h5 sinvineta'><a href=".\download\reglamento-bingos-rifas-bonos.pdf" download >REGLAMENTO DE BINGOS , RIFAS , BINGOS RIFAS , LOTAS , BONOS CONTRIBUCIÓN</a><a href=".\download\R33-p-2024.pdf" download >, Resolucion N° 033-P-2024</a></li>

                <br />
                <li className='h5 sinvineta'><a href=".\download\Reglamento_de_Juego_OnLine.pdf" download >REGLAMENTO DE JUEGO ON LINE</a><a href=".\download\R568-p-2023.pdf" download >, Resolucion N° 568-P-2023</a></li>

                <br />

                <li className='h5 sinvineta'><a href=".\download\REQUISITOS-PARA-ORGANIZAR-BINGOS-Y-RIFAS.pdf" download >REQUISITOS PARA ORGANIZAR BINGOS Y RIFAS</a></li>

                <br />

                <li className='h5 sinvineta'><a href=".\download\MANUAL-PREVENCION-LAVADO-ACTIVOS.pdf" download>MANUAL DE PREVENCIÓN DE LAVADO DE ACTIVOS Y FINANCIAMIENTO DEL TERRORISMO</a></li>

                <br />

                <li className='h5 sinvineta'><a href=".\download\Procedimiento-Sorteos-Tombola-Inprojuy.pdf" download >MANUAL DE PROCEDIMIENTO DE SORTEO DE TOMBOLA</a></li>

                <br />

                <li className='h5 sinvineta'><a href=".\download\Reglamento-TV-BINGO-BONAERENSE-TELEKINO-2023-1.pdf" download>REGLAMENTO TELEKINO</a></li>
                <br />

                <li className='h5 sinvineta'><a href=".\download\Reglamento_de_Salas_Tragamonedas.pdf" download>REGLAMENTO DE SALAS TRAGAMONEDAS</a><a href=".\download\R296-p-2023.pdf" download >, Resolucion N° 296-P-2023</a></li>
            </ul>
            <br />

            <br />

            <hr className="wp-block-separator has-css-opacity" />

            <br />

            <br />

            <ul>
                <li className='h5 sinvineta'><a href=".\download\buenaspalea.pdf" download >Código de Buenas Prácticas para la Publicidad Responsable de Juegos de Azar Online en Argentina</a></li>

                <br /> </ul>
            <br />
        </div>
    )
}

export default Reglamentos