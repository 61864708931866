import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <div>
         <center><h5>Cargando...</h5></center>
    <div className="body">
   
      <div className="coin">
        <span className="engraving"></span>
      </div>
    </div>
    </div>
  );
};

export default Loading;
