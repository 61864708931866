import React from 'react'


const JuegoOnline = () => {
  return (
    <div>
         <a href='https://juj.lotemovil.com.ar/' ><img src= '..\img\placadesvio.jpg' width='100%'/></a>
    </div>
  )
}
export default JuegoOnline