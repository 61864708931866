import React, { useState, useEffect } from 'react';
import { subirSlider, db } from "../../firebase/firebase.config";
import { collection, addDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";

function CargarSlider() {
  const auth = useAuth();
  const [userRole, setUserRole] = useState(null);

  const [files, setFiles] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [isMobile, setIsMobile] = useState(false); // Estado para el checkbox
  const [formValues, setFormValues] = useState({
    Slider: '',
  });
  const [link, setLink] = useState('');
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  
  const loadData = () => {
    axios.get('https://us-central1-inprojuy-f1173.cloudfunctions.net/app/ultimos-sliders')
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener los datos:', error);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const sliderOrder = {
    resultados: 1,
    ganadores_una_mas: 2,
    tercer_slider: 3,
    cuarto_slider: 4,
    sorteo_una_mas: 5,
    telekino: 6,
    quini6: 7,
    brinco: 8,
    ultimo_slider: 9,
    noveno_slider: 10,
    decimo_slider: 11,
  };

  const sortedSlidersData = data.sort((a, b) => {
    const orderA = sliderOrder[a?.Slider] || 9999;
    const orderB = sliderOrder[b?.Slider] || 9999;
    return orderA - orderB;
  });

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFiles(selectedFile);
    }
  };

  const handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    setFormValues(prevValues => ({
      ...prevValues,
      Slider: selectedValue
    }));
    setSelectedOption(selectedValue);

    if (selectedValue === 'resultados') {
      setLink('www.loterialajujena.com.ar');
    } else {
      setLink('');
    }
  };

  const handleCheckboxChange = (e) => {
    setIsMobile(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!files || !selectedOption) {
      alert('Por favor, seleccione una imagen y una opción válida.');
      return;
    }

    setIsSubmitting(true);

    try {
      const url = await subirSlider(files);
      const fechadecarga = Date.now();
      const fechaObjeto = new Date(fechadecarga);
      const fechaLegible = fechaObjeto.toISOString().split('T')[0];
      
      const formData = {
        id: uuidv4(),
        ...formValues,
        imagen: url,
        fechadecarga: fechaLegible,
        ordendecarga: fechadecarga,
        link: selectedOption === 'resultados' ? 'https://juj.lotemovil.com.ar/' : '',
        mobile: isMobile, // Añadir el campo mobile
      };

      await addDoc(collection(db, 'sliders'), formData);
      loadData();
      alert('Los datos se han cargado correctamente');
      setFiles(null);
      setSelectedOption('');
      setLink('');
      setIsMobile(false); // Reiniciar el estado del checkbox
      window.location.reload();
    } catch (error) {
      console.error(error);
      alert('Error al cargar los datos. Inténtalo de nuevo.');
      setFiles(null);
      setSelectedOption('');
      setLink('');
      setIsMobile(false); // Reiniciar el estado del checkbox
      window.location.reload();
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await axios.get(`https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users/${auth.id}/role`);
        setUserRole(response.data.role);
      } catch (error) {
        console.error('Error al obtener el rol del usuario:', error);
      }
    };
    fetchUserRole();
  }, [auth.id]);

  if (userRole !== "admin") {
    return (
      <div className="fondotransparente container">
        <center>
          <h2 className="botonbordeazul text-white px-5 py-3 mb-4">Acceso no autorizado</h2>
          <p>Usted no tiene permiso para acceder a esta página.</p>
        </center>
      </div>
    );
  }

  return (
    <div className='fondotransparente container'>
      <center>
        <h2 className='botonbordeazul text-white px-5 py-3 mb-4'>Cargar slider</h2>
      </center>
      <h4>Sliders cargados actualmente:</h4>
      <div className="thumbnails-container">
        {sortedSlidersData.map((item, index) => (
          <div key={index} className="thumbnail">
            <img src={item.imagen} alt={item.Slider} />
            <p className="slider-caption">{item.Slider}</p>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="contactform row g-3 mt-4">
        <div>
          <small> * Arriba se muestran los sliders cargados actualmente y el nombre relacionado con la opción a seleccionar. Seleccione el Slider a reemplazar, seleccione el archivo que tiene la imagen y haga clic en Cargar. Espere unos segundos mientras se carga y verá reflejado arriba la nueva disposición de los sliders.</small>
          <p></p><small> Si sube mal una imagen solo debe reemplazar nuevamente el slider con la imagen correcta. Se sugiere que cargue una imagen rectangular horizontal de 1200 x 500 píxeles o similar para no alterar la forma del slider</small>
        </div>
        <div className='col-md-12 m-0 p-0'>
          <label className="h5 form-label">Seleccione una opción:</label>
          <select className="form-select" name="tipo" value={selectedOption} onChange={handleOptionChange} required>
            <option value="">Seleccione una opción</option>
            <option value="resultados">Resultados</option>
            <option value="ganadores_una_mas">Ganadores una mas</option>
            <option value="tercer_slider">Tercer Slider</option>
            <option value="cuarto_slider">Cuarto Slider</option>
            <option value="sorteo_una_mas">Sorteo una mas</option>
            <option value="telekino">Telekino</option>
            <option value="quini6">Quini 6</option>
            <option value="brinco">Brinco</option>
            <option value="ultimo_slider">Ultimo Slider</option>
            <option value="noveno_slider">Noveno Slider</option>
            <option value="decimo_slider">Decimo Slider</option>
          </select>
          <br />
          <label className="h5 form-label">Cargar imagen:</label>
          <input type='file' accept="image/jpeg, image/png" className="form-control" onChange={handleChange} />
          <br />
          <label className="h5 form-label">Mostrar en móvil:</label>
          <input type="checkbox" checked={isMobile} onChange={handleCheckboxChange} />
        </div>
        <div>
          <button type="submit" className='btn btn-success' disabled={isSubmitting}>Cargar</button>
          <a>   </a>
          <button className='btn btn-outline-danger' disabled={isSubmitting} onClick={() => navigate('/login')}>Volver</button>
        </div>
      </form>
    </div>
  );
}

export default CargarSlider;
