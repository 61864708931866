import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid, enUS } from '@mui/x-data-grid';

import { daDK } from '@mui/x-data-grid/locales';
import nbNO from '@mui/x-data-grid/locales/nbNO';


import * as XLSX from 'xlsx';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const BDusuarios = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sorteos, setSorteos] = useState([]);
  const [selectedSorteos, setSelectedSorteos] = useState({});
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
 
  useEffect(() => {
    const url = 'https://us-central1-inprojuy-f1173.cloudfunctions.net/app/users';

    axios.get(url)
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al obtener los datos:', error);
        setLoading(false);
      });

    axios.get('https://us-central1-inprojuy-f1173.cloudfunctions.net/app/sorteos')
      .then((response) => setSorteos(response.data))
      .catch(console.error);
  }, []);

  const handleSorteoChange = (userId, event) => {
    setSelectedSorteos({ ...selectedSorteos, [userId]: event.target.value });
  };

  const columns = [
    {
        field: 'id',
        headerName: 'Buscar los cupones del usuario',
        width: 350,
        renderCell: (params) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <select value={selectedSorteos[params.value] || ''} onChange={(event) => handleSorteoChange(params.value, event)}>
              <option value="">Seleccione sorteo</option>
              {sorteos.map((sorteo) => (
                <option key={sorteo.n_sorte} value={sorteo.n_sorte}>
                  {sorteo.n_sorte} - {sorteo.mes}
                </option>
              ))}
            </select>
            <div style={{ width: '10px' }} /> {/* Espacio entre el combo y el botón */}
            <Button className='' disabled={!selectedSorteos[params.value]} onClick={() => navigate(`/cupones/${params.value}/${selectedSorteos[params.value]}`)}>Cupones cargados</Button>
          </div>
        ),
      },
    { field: 'apellido', headerName: 'Apellido', width: 130 },
    { field: 'nombre', headerName: 'Nombre', width: 130 },
    { field: 'telefono', headerName: 'Teléfono', width: 130 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'dni', headerName: 'DNI', width: 130 },
    { field: 'rol', headerName: 'Rol', width: 90 },
    { field: 'username', headerName: 'Username', width: 150 },
    { field: 'barrio', headerName: 'Barrio', width: 130 },
    { field: 'fechaNacimiento', headerName: 'Fecha de Nacimiento', width: 180 },
    { field: 'direccion', headerName: 'Dirección', width: 200 },
    { field: 'localidad', headerName: 'Localidad', width: 150 },
  ];

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Usuarios');
    XLSX.writeFile(wb, 'usuarios.xlsx');
  };

  return (
    <Container style={{ height: 650, width: '100%' }}>
      <div style={{ height: 500, width: '100%' }}>
        <p></p>
        <center><h1 className='botonbordeazul p-2 text-white'>Base de Datos de Usuarios</h1></center>
        <button  onClick={exportToExcel}>Exportar a Excel</button> <a></a>
        <button  className='red' onClick={() => navigate('/login')}>Volver</button>
        <p></p>

        {loading ? (
         <div id="load">
         <div>O</div>
         <div>D</div>
         <div>N</div>
         <div>A</div>
         <div>G</div>
         <div>R</div>
         <div>A</div>
         <div>C</div>
       </div>
        ) : (
          <DataGrid
            rows={users}
            columns={columns}
            pageSize={pageSize}
            localeText={{
                MuiTablePagination: {
                    labelRowsPerPage: 'Filas por página:' ,
                    labelDisplayedRows: function defaultLabelDisplayedRows({ from, to, count }) { return `${from}–${to} de ${count !== -1 ? count : `more than ${to}`}`; }

                },
              }}

            pagination
            onPageChange={(params) => {
              setCurrentPage(params.page);
            }}
            onPageSizeChange={(params) => {
              setPageSize(params.pageSize);
            }}
          />
        )}
      </div>
    </Container>
  );
};

export default BDusuarios;
